import React from 'react'
import $ from './util/jqueryShim'
import 'owl.carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import './Reviews.scss'
import { isMobileOnly } from 'react-device-detect'

import { ReactComponent as ArrowRight } from './assets/arrow-right.svg'
import { ReactComponent as Avatar } from '../../../assets/avatar-plug.svg'

import bootstrapService from '../../../services/BootstrapService'

class Reviews extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.carousel = null;

        // This Binding
        this.clickNextReview = this.clickNextReview.bind(this);
    }

    // Lifecycle
    componentDidMount() {
        this.setupCarousel();
    }

    // Helpers
    setupCarousel() {
        $(".a4-client-reviews-list").owlCarousel({
            animateOut: "azaza",
            smartSpeed: 700,
            autoWidth: true,
            margin: 26,
        });
    }

    // Event Handlers
    clickNextReview() {
        $(this.carousel).trigger("next.owl.carousel");
    }

    // Component
    render() {
        const reviewsData = bootstrapService.data.landing.sections.find(s => s.name === "testimonials");

        const header = reviewsData ? reviewsData.header : null;
        const body = reviewsData ? reviewsData.body : null;
        const reviews = reviewsData ? reviewsData.testimonials : null;

        return (
            <>
                {
                    reviewsData &&
                    <section className="a4-client-reviews">
                        <div className="a4-main-client-reviews-container">
                            <div className="a4-main-client-reviews-content">
                                <div>
                                    <div className="a4-client-reviews-info">
                                        <div className="a4-client-reviews-title" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                            <h2>{header}</h2>
                                        </div>
                                        <div className="a4-client-reviews-description" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                            <p>{body}</p>
                                        </div>
                                    </div>
                                    <div className="relative">
                                        <div className="a4-client-reviews-list-container">
                                            <div className="a4-client-reviews-list-control" onClick={this.clickNextReview}>
                                                <svg className="a4-svgsprite -logo text-secondary">
                                                    <ArrowRight fill={bootstrapService.primaryColor}/>
                                                </svg>
                                            </div>
                                            <ul className="a4-client-reviews-list owl-carousel" ref={div => this.carousel = div}>
                                                {
                                                    reviews.map((review, index) => {
                                                        return <li className="a4-client-reviews-list-item" data-aos={isMobileOnly ? undefined : "fade-up"} key={index} style={{cursor: "grab"}}>
                                                            <div className="a4-client-reviews-list-item-user">
                                                                <div className="a4-client-reviews-list-item-user-avatar">
                                                                    {
                                                                        review.imageUrl &&
                                                                        <img src={review.imageUrl} height="50" width="50" alt="" />
                                                                    }
                                                                    {
                                                                        !review.imageUrl &&
                                                                        <Avatar height="50" width="50" />
                                                                    }
                                                                    
                                                                </div>
                                                                <div className="a4-client-reviews-list-item-user-info">
                                                                    <div className="a4-client-reviews-list-item-user-name">{review.name}</div>
                                                                    <div className="a4-client-reviews-list-item-user-from">{review.location}</div>
                                                                </div>
                                                            </div>
                                                            <div className="a4-client-reviews-list-item-review">
                                                                <p>{review.comment}</p>
                                                            </div>
                                                        </li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }
            </>
        )
    }
}

export default Reviews;