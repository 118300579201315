import React from 'react'
import './Footer.scss'
import { Link } from 'react-router-dom'

import BubbleButton from '../BubbleButton/BubbleButton'
import { ReactComponent as Apple } from '../../../assets/apple.svg'
import { ReactComponent as Google } from '../../../assets/google.svg'

import bootstrapService from '../../../services/BootstrapService'

class Footer extends React.Component {
    // Component
    render() {
        const siteData = bootstrapService.data.site;
        const navs = bootstrapService.data.landing.footers;

        const logo = bootstrapService.data.site.logoLightUrl;

        const defaultPassType = bootstrapService.defaultPlanType;
        const defaultStateName = bootstrapService.defaultStateName;

        const siteName = siteData.name;
        const phone = siteData.sitePhone;
        const fax = siteData.siteFax;
        const email = siteData.siteEmail;
        const address = siteData.siteAddress.street.concat(" ", siteData.siteAddress.city, ", ", siteData.siteAddress.state, " ", siteData.siteAddress.zip);

        const homeData = bootstrapService.data.landing;
        const appStoreData = homeData.sections.find(s => s.name === "appStore");
        const appleUrl = appStoreData ? appStoreData.appleUrl : null;
        const googleUrl = appStoreData ? appStoreData.googleUrl : null;

        const ceTerm = bootstrapService.getCETerm();

        const showAddress = bootstrapService.getShowAddress();

        const year = new Date().getFullYear()

        return (
            <section className="a4-footer">
                <div className="a4-footer-container">
                    <div className="a4-footer-filler"></div>
                    <div className="a4-footer-content">
                        <div className="a4-footer-left">
                            <div>
                                <div className="a4-footer-logo">
                                    <img src={logo} alt="" />
                                </div>
                                <div className="a4-footer-copyright">© Copyright {year} {siteName}</div>
                            </div>
                            <div className="a4-footer-app-store">
                                {
                                    appleUrl &&
                                    <a href={appleUrl} target="_blank" rel="noreferrer">
                                        <BubbleButton className="a4-btn_store -fixed -bordered">
                                            <div className="a4-btn_store-icon">
                                                <svg className="a4-svgsprite -app-store">
                                                    <Apple />
                                                </svg>
                                            </div>
                                            <div className="a4-btn_store-text">Available in <b>App Store</b></div>
                                        </BubbleButton>
                                    </a>
                                }
                                {
                                    googleUrl &&
                                    <a href={googleUrl} target="_blank" rel="noreferrer">
                                        <BubbleButton className="a4-btn_store -fixed -bordered">
                                            <div className="a4-btn_store-icon">
                                                <svg className="a4-svgsprite -gplay-store">
                                                    <Google />
                                                </svg>
                                            </div>
                                            <div className="a4-btn_store-text">Get it on <b>Google Play</b></div>
                                        </BubbleButton>
                                    </a>
                                }
                            </div>
                        </div>
                        <div className="a4-footer-right">
                            <div className="a4-footer-list">
                                <div className="a4-footer-list-title">
                                    <h4>Company</h4>
                                    <ul>
                                        {
                                            navs.find(n => n === "approvals") &&
                                            <li>
                                                <Link to="/approvals">Our Approvals</Link>
                                            </li>
                                        }
                                        {
                                            navs.find(n => n === "courses") &&
                                            <li>
                                                {
                                                    bootstrapService.singleCourseId &&
                                                    <Link to={`/course/${bootstrapService.singleCourseId}`}>The Course</Link>
                                                }
                                                {
                                                    !bootstrapService.singleCourseId &&
                                                    <Link to="/courses">Our Courses</Link>
                                                }
                                            </li>
                                        }
                                        {
                                            navs.find(n => n === "pricing") &&
                                            <li>
                                                <Link to={`/pricing/${defaultPassType}`}>Pricing</Link>
                                            </li>
                                        }
                                        {
                                            navs.find(n => n === "faq") &&
                                            <li>
                                                <Link to="/faq">FAQ</Link>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="a4-footer-list">
                                <div className="a4-footer-list-title">
                                    <h4>Useful Links</h4>
                                    <ul>
                                        {
                                            navs.find(n => n === "help-support") &&
                                            <li>
                                                <Link to="/help-and-support">Help & Support</Link>
                                            </li>
                                        }
                                        {
                                            navs.find(n => n === "requirements") &&
                                            <li>
                                                <Link to={`/requirements/${defaultStateName}`}>{ceTerm} Requirements</Link>
                                            </li>
                                        }
                                        {
                                            navs.find(n => n === "our-sites") &&
                                            <li>
                                                <Link to="/affiliated-sites">Affiliated Sites</Link>
                                            </li>
                                        }
                                        {
                                            navs.find(n => n === "terms") &&
                                            <li>
                                                <Link to="/terms-and-conditions">Terms & Conditions</Link>
                                            </li>
                                        }
                                        {
                                            navs.find(n => n === "privacy") &&
                                            <li>
                                                <Link to="/privacy-policy">Privacy Policy</Link>
                                            </li>
                                        }
                                        {
                                            navs.find(n => n === "refund-policy") &&
                                            <li>
                                                <Link to="/refund-policy">Refund Policy</Link>
                                            </li>
                                        }
                                        {
                                            navs.find(n => n === "complaint-resolution-policy") &&
                                            <li>
                                                <Link to="/complaint-resolution-policy">Complaint Resolution Policy</Link>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="a4-footer-list">
                            <div className="a4-footer-list-title">
                                    <h4>Contact</h4>
                                    <ul>
                                        <li className="a4-footer-list-item-nowrap"> 
                                            Email: 
                                            <a href={`mailto:${email}`} target="_blank" rel="noreferrer"> {email}</a>
                                        </li>
                                        <li> 
                                            Phone:
                                            <a href={`tel:${phone}`}> {phone}</a>
                                        </li>
                                        <li> 
                                            Fax:
                                            <a href={`fax:${fax}`}> {fax}</a>
                                        </li>
                                        {
                                            showAddress &&
                                            <li>
                                                Address:
                                                <a href={`http://maps.google.com/?q=${address}`} target="_blank" rel="noreferrer">
                                                    &nbsp; {address}
                                                </a>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Footer;