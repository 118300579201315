import React from 'react'
import './Empty.scss'
import { withRouter } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'

import bootstrapService from '../../../services/BootstrapService'

class Empty extends React.Component {
    render() {
        const emptyTitle = this.props.location.state ? this.props.location.state.title : "No courses matched your search";
        const emptyImg = bootstrapService.data ? bootstrapService.data.site.noResultsImageUrl : "";

        return (
            <div className="a4-empty">
                <div className="a4-empty-exposition">
                    <div className="a4-empty-exposition-container">
                        <div className={`a4-empty-exposition-content -${this.props.name}`}>
                            <div className="a4-empty-exposition-info">
                                <h1 className="a4-empty-exposition-title" data-aos={isMobileOnly ? undefined : "fade-up"}>{emptyTitle}</h1>
                            </div>
                            <div className="a4-empty-exposition-illustration">
                                <img src={emptyImg} data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-easing="ease-out-quad" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Empty);