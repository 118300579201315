import React from 'react'
import './ComplaintPolicy.scss'
import { splitBody } from '../../utils/string'
import { isMobileOnly } from 'react-device-detect'

import Navbar from '../shared/Navbar/Navbar'
import Footer from '../shared/Footer/Footer'
import { ReactComponent as Letter } from '../../assets/letter.svg'
import { ReactComponent as Phone } from '../../assets/phone.svg'
import { ReactComponent as Location } from '../../assets/location.svg'
import { ReactComponent as Printer } from '../../assets/printer.svg'

import bootstrapService from '../../services/BootstrapService'

class ComplaintPolicy extends React.Component {

    // Lifecycle
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    
    // Component
    render() {
        const policyData = bootstrapService.data.landing.pages.find(p => p.name === "complaint-resolution-policy");

        const header = policyData ? policyData.header : null;
        const effectiveDate = policyData ? policyData.effectiveDate : null;
        const body = policyData ? policyData.body : null;

        const [body1, body2] = splitBody(body);

        const siteData = bootstrapService.data.site;

        const email = siteData.siteEmail;
        const phone = siteData.sitePhone;
        const address1 = siteData.siteAddress.street;
        const address2 = siteData.siteAddress.city.concat(", ", siteData.siteAddress.state, " ", siteData.siteAddress.zip);
        const fullAddress = address1.concat(" ", address2);
        const fax = siteData.siteFax;
        
        return (
            <>
                {
                    policyData &&
                    <>
                        <Navbar />
                        <div className="a4-view">
                            <div className="a4-layout a4-policy">
                                <div className="a4-policy-container">
                                    <div className="a4-policy-page-title" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                        <h1 className="title">{header}</h1>
                                    </div>
                                    <div className="a4-policy-pre" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                        <h4 className="title">Effective date: {effectiveDate}.</h4>
                                        <div className="cols">
                                            <div className="column">
                                                <p><span className="text-primary" dangerouslySetInnerHTML={{ __html: body1 }}></span></p>
                                            </div>
                                            <div className="column">
                                                <p><span className="text-primary" dangerouslySetInnerHTML={{ __html: body2 }}></span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="a4-policy-contacts" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                        <ul className="a4-policy-contacts-list">
                                            <li className="a4-policy-contacts-list-item">
                                                <div className="a4-policy-contacts-list-item-info">
                                                    <div className="a4-policy-contacts-list-item-label">Email:</div>
                                                    <a href={`mailto:${email}`}>
                                                        <div className="a4-policy-contacts-list-item-value">{email}</div>
                                                    </a>
                                                </div>
                                                <div className="a4-policy-contacts-list-item-icon">
                                                    <svg className="a4-svgsprite -letter text-secondary">
                                                        <Letter fill={bootstrapService.primaryColor} />
                                                    </svg>
                                                </div>
                                            </li>
                                            <li className="a4-policy-contacts-list-item">
                                                <div className="a4-policy-contacts-list-item-info">
                                                    <div className="a4-policy-contacts-list-item-label">Phone:</div>
                                                    <a href={`tel:${phone}`}>
                                                        <div className="a4-policy-contacts-list-item-value">{phone}</div>
                                                    </a>
                                                </div>
                                                <div className="a4-policy-contacts-list-item-icon">
                                                    <svg className="a4-svgsprite -phone text-secondary">
                                                        <Phone stroke={bootstrapService.primaryColor} />
                                                    </svg>
                                                </div>
                                            </li>
                                            <li className="a4-policy-contacts-list-item">
                                                <div className="a4-policy-contacts-list-item-info">
                                                    <div className="a4-policy-contacts-list-item-label">Mailing Address:</div>
                                                    <a href={`http://maps.google.com/?q=${fullAddress}`} target="_blank" rel="noreferrer">
                                                        <div className="a4-policy-contacts-list-item-value">{address1}<br></br>{address2}</div>
                                                    </a>
                                                </div>
                                                <div className="a4-policy-contacts-list-item-icon">
                                                    <svg className="a4-svgsprite -location text-secondary">
                                                        <Location stroke={bootstrapService.primaryColor} />
                                                    </svg>
                                                </div>
                                            </li>
                                            <li className="a4-policy-contacts-list-item">
                                                <div className="a4-policy-contacts-list-item-info">
                                                    <div className="a4-policy-contacts-list-item-label">Fax:</div>
                                                    <a href={`fax:${fax}`}>
                                                        <div className="a4-policy-contacts-list-item-value">{fax}</div>
                                                    </a>
                                                </div>
                                                <div className="a4-policy-contacts-list-item-icon">
                                                    <svg className="a4-svgsprite -printer text-secondary">
                                                        <Printer stroke={bootstrapService.primaryColor} />
                                                    </svg>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </>
                }
            </>
        )
    }
}

export default ComplaintPolicy;
