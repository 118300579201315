import React from 'react'
import './Plans.scss'
import { Link, withRouter } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'

class Plans extends React.Component {
    // Component
    render() {
        const headers = this.props.data.headers;
        const plans = this.props.data.plans;

        return (
            <section className="a4-find-plan">
                <div className="a4-find-plan-container">
                    <div className="a4-find-plan-content">
                        <div className="a4-find-plan-title" data-aos={isMobileOnly ? undefined : "fade-up"}>
                            <h1>{headers[0]} <span className="text-primary">{headers[1]}</span></h1>
                        </div>
                        {/* ONLY 1 PLAN */}
                        {
                            this.props.data && plans.length === 1 && 
                            <ul className="a4-one-plan-list">
                            {
                                plans.map((plan, index) => {
                                    return <li className="a4-one-plan-list-item-wrapper" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-delay={`${100 + index * 150}`} data-aos-anchor-placement="top-bottom" key={index}>
                                        {
                                            plan.type !== "group-plans" &&
                                            <Link to={`/pricing/${plan.type}`}>
                                                <div className="a4-one-plan-list-item -active" data-type={plan.type}>
                                                    <div className="a4-one-plan-list-item-image">
                                                        <img src={plan.imageUrl} width="153px" height="147px" alt="" />
                                                    </div>
                                                    <div className="a4-one-plan-list-item-title">
                                                        <h4>{plan.title}</h4>
                                                    </div>
                                                    <div className="a4-one-plan-list-item-description">
                                                        <p>
                                                            <span className="a4-one-plan-list-item-programs">{plan.description}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </Link>
                                        }
                                        {
                                            plan.type === "group-plans" &&
                                            <div className="a4-one-plan-list-item -active" data-type={plan.type} onClick={this.props.openGroupPlanModal}>
                                                <div className="a4-one-plan-list-item-image">
                                                    <img src={plan.imageUrl} width="153px" height="147px" alt="" />
                                                </div>
                                                <div className="a4-one-plan-list-item-title">
                                                    <h4>{plan.title}</h4>
                                                </div>
                                                <div className="a4-one-plan-list-item-description">
                                                    <p>
                                                        <span className="a4-three-plan-list-item-programs">{plan.description}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        }
                                    </li>
                                })
                            }
                            </ul>
                        }
                        {/* EVEN NUMBER OF PLANS */}
                        {
                            this.props.data && plans.length > 1 && plans.length % 2 === 0 && 
                            <ul className="a4-four-plan-list">
                            {
                                plans.map((plan, index) => {
                                    return <li className="a4-four-plan-list-item-wrapper" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-delay={`${100 + index * 150}`} data-aos-anchor-placement="top-bottom" key={index}>
                                        {
                                            plan.type !== "group-plans" &&
                                            <Link to={`/pricing/${plan.type}`}>
                                                <div className="a4-four-plan-list-item -active" data-type={plan.type}>
                                                    <div className="a4-four-plan-list-item-image">
                                                        <img src={plan.imageUrl} width="153px" height="147px" alt="" />
                                                    </div>
                                                    <div className="a4-four-plan-list-item-title">
                                                        <h4>{plan.title}</h4>
                                                    </div>
                                                    <div className="a4-four-plan-list-item-description">
                                                        <p>
                                                            <span className="a4-three-plan-list-item-programs">{plan.description}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </Link>
                                        }
                                        {
                                            plan.type === "group-plans" &&
                                            <div className="a4-four-plan-list-item -active" data-type={plan.type} onClick={this.props.openGroupPlanModal}>
                                                <div className="a4-four-plan-list-item-image">
                                                    <img src={plan.imageUrl} width="153px" height="147px" alt="" />
                                                </div>
                                                <div className="a4-four-plan-list-item-title">
                                                    <h4>{plan.title}</h4>
                                                </div>
                                                <div className="a4-four-plan-list-item-description">
                                                    <p>
                                                        <span className="a4-three-plan-list-item-programs">{plan.description}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        }
                                    </li>
                                })
                            }
                            </ul>
                        }
                        {/* ODD NUMBER OF PLANS */}
                        {
                            this.props.data && plans.length > 1 && plans.length % 2 === 1 &&
                            <ul className="a4-three-plan-list">
                            {
                                plans.map((plan, index) => {
                                    return <li className="a4-three-plan-list-item-wrapper" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-delay={`${100 + index * 150}`} data-aos-anchor-placement="top-bottom" key={index}>
                                        {
                                            plan.type !== "group-plans" &&
                                            <Link to={`/pricing/${plan.type}`}>
                                                <div className="a4-three-plan-list-item -active">
                                                    <div className="a4-three-plan-list-item-image">
                                                        <img src={plan.imageUrl} alt="" />
                                                    </div>
                                                    <div className="a4-three-plan-list-item-title">
                                                        <h4>{plan.title}</h4>
                                                    </div>
                                                    <div className="a4-three-plan-list-item-description">
                                                        <p>
                                                            <span className="a4-three-plan-list-item-programs">{plan.description}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </Link>
                                        }
                                        {
                                            plan.type === "group-plans" &&
                                            <div className="a4-three-plan-list-item -active" data-type={plan.type} onClick={this.props.openGroupPlanModal}>
                                                <div className="a4-three-plan-list-item-image">
                                                    <img src={plan.imageUrl} alt="" />
                                                </div>
                                                <div className="a4-three-plan-list-item-title">
                                                    <h4>{plan.title}</h4>
                                                </div>
                                                <div className="a4-three-plan-list-item-description">
                                                    <p>
                                                        <span className="a4-three-plan-list-item-programs">{plan.description}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        }
                                    </li>
                                })  
                            }                                
                            </ul>
                        }
                    </div>
                </div>
            </section>
        )
    }
}

export default withRouter(Plans);