import React from 'react'
import './AppError.scss'
import { isMobileOnly } from 'react-device-detect'

class AppError extends React.Component {
    render() {
        return (
            <div className="a4-view">
                <div className="a4-layout a4-app-error">
                    <div className="a4-app-error-content">
                        <div className={"a4-app-error-exposition -highlighted"}>
                            <div className="a4-app-error-exposition-container">
                                <div className="a4-app-error-exposition-content">
                                    <div className="a4-app-error-exposition-info" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                        <h1 className="a4-app-error-exposition-code">Error</h1>
                                        <h3 className="a4-app-error-exposition-title"><span className="text-primary">App Failed</span></h3>
                                        <p className="a4-app-error-exposition-description">The application failed to initialize.<br></br>Please contact customer support for assistance.<br></br>Thank you.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AppError;