import httpService from './HttpService';
import sessionService from './SessionService'
import { MemberType } from './MemberService'

class PaymentService {

    constructor() {
        // State
        this.memberType = "register";
        this.paymentType = "cc";

        // Data
        this.member = null;
        this.item = null;
        this.schedule = null;
        this.group = null;
        this.creditCard = null;
        this.sandboxTicket = null;

        // Validation
        this.paypalValidated = false;
    }

    reset() {
        this.memberType = "register";
        this.paymentType = "cc";
        
        this.member = null;
        this.item = null;
        this.schedule = null;
        this.group = null;
        this.creditCard = null;
        this.sandboxTicket = null;
    }
    
    getSandboxTicket() {
        const url = `${httpService.apiUrl}/api/test/sandbox/ticket`;
        const headers = {
            "Content-Type": "application/json",
            "HssEdSiteName": httpService.siteName,
            "Authorization": `Bearer ${httpService.apiToken}`,
            "HssEdSessionId": httpService.sessionId,
            "HssEdClientId": httpService.hssEdClientId,
            "HssEdClientSecret": httpService.hssEdClientSecret,
            "HssEdClientPlatform": httpService.hssEdClientPlatform
        };
        const body = {};

        return httpService.post(url, headers, body)
        .then(json => {
            if (json.succeeded) {
                this.sandboxTicket = json.value;
            }

            return json;
        });
    }

    buyItemCreditCard(buyRequest) {
        if (this.member && this.item && this.creditCard) {
            const url = `${httpService.apiUrl}/api/order/process`;
            const headers = {
                "Content-Type": "application/json",
                "HssEdSiteName": httpService.siteName,
                "Authorization": `Bearer ${httpService.apiToken}`,
                "HssEdSessionId": httpService.sessionId,
                "HssEdClientId": httpService.hssEdClientId,
                "HssEdClientSecret": httpService.hssEdClientSecret,
                "HssEdClientPlatform": httpService.hssEdClientPlatform
            };
            const body = {
                "recaptchaToken": buyRequest.recaptchaToken,
                "acceptedTerms": true,
                "memberRequest": {
                    "requestType": this.member.requestType,
                    "email": this.member.email,
                    "password": this.member.password,
                    "firstName": this.member.firstName,
                    "lastName": this.member.lastName,
                    "memberType": MemberType.Payment,
                    "autoLogin": true,
                    "origin": window.location.origin,
                    "timezoneOffset": this.member.timezoneOffset
                },
                "itemRequests": [
                    {
                        "itemId": this.item.id,
                        "quantity": this.item.quantity,
                        "scheduleId": this.schedule ? this.schedule.scheduleId : null,
                        "args": {
                            "name": this.group ? this.group.groupName : null,
                            "adminOnly": this.group ? this.group.isAdminOnly : null,
                        }
                    }
                ],
                "paymentRequests": [
                    {
                        "paymentType": 1,
                        "creditCardNo": this.creditCard.creditCardNo,
                        "expirationMonth": this.creditCard.expirationMonth,
                        "expirationYear": this.creditCard.expirationYear,
                        "securityCode": this.creditCard.securityCode,
                        "sandboxTicket": this.sandboxTicket
                    }
                ]
            };

            return httpService.post(url, headers, body)
            .then(json => {
                if (json.succeeded) {
                    sessionService.setLocalUserSession(json);
                }
                return json;
            });
        }
        else {
            console.log("Buy Item Register Failed - Missing Data");
        }
    }

    buyItemPayPal(buyRequest) {
        if (this.member && this.item) {
            const url = `${httpService.apiUrl}/api/order/process`;
            const headers = {
                "Content-Type": "application/json",
                "HssEdSiteName": httpService.siteName,
                "Authorization": `Bearer ${httpService.apiToken}`,
                "HssEdSessionId": httpService.sessionId,
                "HssEdClientId": httpService.hssEdClientId,
                "HssEdClientSecret": httpService.hssEdClientSecret,
                "HssEdClientPlatform": httpService.hssEdClientPlatform
            };
            const body = {
                "recaptchaToken": buyRequest.recaptchaToken,
                "acceptedTerms": true,
                "memberRequest": {
                    "requestType": this.member.requestType,
                    "email": this.member.email,
                    "password": this.member.password,
                    "firstName": this.member.firstName,
                    "lastName": this.member.lastName,
                    "memberType": MemberType.Payment,
                    "autoLogin": true,
                    "origin": window.location.origin,
                    "timezoneOffset": this.member.timezoneOffset
                },
                "itemRequests": [
                    {
                        "itemId": this.item.id,
                        "quantity": this.item.quantity,
                        "scheduleId": this.schedule ? this.schedule.scheduleId : null,
                        "args": {
                            "name": this.group ? this.group.name : null,
                            "adminOnly": this.group ? this.group.isAdminOnly : null,
                        }
                    }
                ],
                "paymentRequests": [
                    {
                        "paymentType": 2,
                        "pathname": "/",
                        "hash": "Pricing",
                        "payPalReturnUrl": `${window.location.origin}/complete-paypal`,
                        "creditCardNo": null,
                        "expirationMonth": null,
                        "expirationYear": null,
                        "securityCode": null,
                        "sandboxTicket": this.sandboxTicket,
                        "payerId": buyRequest.payerId,
                    }
                ]
            };

            return httpService.post(url, headers, body);
        }
        else {
            console.log("Buy Item PayPal Failed - Missing Data")
        }
    }

    buyItemOffline(buyRequest) {
        if (this.member && this.item) {
            const url = `${httpService.apiUrl}/api/order/process`;
            const headers = {
                "Content-Type": "application/json",
                "HssEdSiteName": httpService.siteName,
                "Authorization": `Bearer ${httpService.apiToken}`,
                "HssEdSessionId": httpService.sessionId,
                "HssEdClientId": httpService.hssEdClientId,
                "HssEdClientSecret": httpService.hssEdClientSecret,
                "HssEdClientPlatform": httpService.hssEdClientPlatform
            };
            const body = {
                "recaptchaToken": buyRequest.recaptchaToken,
                "acceptedTerms": true,
                "memberRequest": {
                    "requestType": this.member.requestType,
                    "email": this.member.email,
                    "password": this.member.password,
                    "firstName": this.member.firstName,
                    "lastName": this.member.lastName,
                    "memberType": MemberType.Payment,
                    "autoLogin": true,
                    "origin": window.location.origin,
                    "timezoneOffset": this.member.timezoneOffset
                },
                "itemRequests": [
                    {
                        "itemId": this.item.id,
                        "quantity": this.item.quantity,
                        "scheduleId": this.schedule ? this.schedule.scheduleId : null,
                        "args": {
                            "name": this.group ? this.group.name : null,
                            "adminOnly": this.group ? this.group.isAdminOnly : null,
                        }
                    }
                ],
                "paymentRequests": [
                    {
                        "paymentType": 4,
                        "sandboxTicket": this.sandboxTicket
                    }
                ]
            };

            return httpService.post(url, headers, body)
            .then(json => {
                if (json.succeeded) {
                    sessionService.setLocalUserSession(json);
                }
                return json;
            });
        }
        else {
            console.log("Buy Item PayPal Failed - Missing Data")
        }
    }

    getPayPalOrder(token) {
        const url = `${httpService.apiUrl}/api/order/filterone`;
        const headers = {
            "Content-Type": "application/json",
            "HssEdSiteName": httpService.siteName,
            "Authorization": `Bearer ${httpService.apiToken}`,
            "HssEdSessionId": httpService.sessionId,
            "HssEdClientId": httpService.hssEdClientId,
            "HssEdClientSecret": httpService.hssEdClientSecret,
            "HssEdClientPlatform": httpService.hssEdClientPlatform
        };
        const body = {
            token: token,
        };

        return httpService.post(url, headers, body);
    }

    cancelPayPalPayment(orderId, paymentRequestId) {
        const url = `${httpService.apiUrl}/api/order/${orderId}/payment/${paymentRequestId}/cancel`;
        const headers = {
            "Content-Type": "application/json",
            "HssEdSiteName": httpService.siteName,
            "Authorization": `Bearer ${httpService.apiToken}`,
            "HssEdSessionId": httpService.sessionId,
            "HssEdClientId": httpService.hssEdClientId,
            "HssEdClientSecret": httpService.hssEdClientSecret,
            "HssEdClientPlatform": httpService.hssEdClientPlatform
        };
        const body = null;

        return httpService.post(url, headers, body);
    }
}

const paymentService = new PaymentService();
export default paymentService;