import React from 'react'
import './Pages.scss'

import { withStyles } from "@material-ui/core/styles";
import Pagination from '@material-ui/lab/Pagination';

const styles = theme => ({
    root: {
        "& .Mui-selected": {
            color: "white",
            backgroundColor: "var(--primary)",
           },
        "& .MuiPaginationItem-root:hover": {
            color: "black",
            backgroundColor: "#F8F9FA",
        }
    }
});

class Pages extends React.Component {
    // Component
    render() {
        return (
            <div className="a4-pagination">
                <Pagination {...this.props} />
            </div>
        )
    }
}

export default withStyles(styles)(Pages);