import React from 'react'
import './CustomPage.scss'
import { isMobileOnly } from 'react-device-detect'

import Navbar from '../shared/Navbar/Navbar'
import Subscribe from '../shared/Subscribe/Subscribe'
import Footer from '../shared/Footer/Footer'

import bootstrapService from '../../services/BootstrapService'

class CustomPage extends React.Component {
    // Constructor
    constructor(props) {
        super(props);
    }

    // Lifecycle
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    // Component
    render() {
        const pages =  bootstrapService.data.landing.pages;
        const page = pages.find(p => p.name === this.props.match.params.name);

        if (!page) {
            this.props.history.push("/");
        }

        const header = page ? page.header : null;
        const body = page ? page.body : null;

        return (
            <>
                {
                    page &&
                    <>
                        <Navbar />
                        <div className="a4-view">
                            <div className="a4-layout a4-custom-page">
                                <div className="a4-custom-page-container">
                                    <div className="a4-custom-page-page-title" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                        <h1 className="title">{header}</h1>
                                        <div className="a4-custom-page-body" dangerouslySetInnerHTML={{ __html: body }}></div>
                                    </div>
                                </div>
                                <Subscribe />
                                <Footer />
                            </div>
                        </div>
                    </>
                }
            </>
        )
    }
}

export default CustomPage;