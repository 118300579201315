import React from 'react'
import './Terms.scss'
import { splitBody } from '../../utils/string'
import { splitArray } from '../../utils/array'
import { isMobileOnly } from 'react-device-detect'

import Navbar from '../shared/Navbar/Navbar'
import Footer from '../shared/Footer/Footer'
import { ReactComponent as ArrowDown } from '../../assets/arrow-down.svg'

import bootstrapService from '../../services/BootstrapService'

class Terms extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.topicRefs = new Array([]);

        // This Binding
        this.clickTopic = this.clickTopic.bind(this);
    }

    // Lifecycle
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    // Event Handlers
    clickTopic(e) {
        const topic = e.currentTarget.getAttribute("topic");
        for (const ref of this.topicRefs) {
            if (ref.getAttribute("topic") === topic) {
                ref.scrollIntoView({ behavior: 'smooth', block: 'center' });
                return;
            }
        }
    }

    // Component
    render() {
        const termsData = bootstrapService.data ? bootstrapService.data.landing.pages.find(p => p.name === "terms") : null;

        const header = termsData ? termsData.header : null;
        const effectiveDate = termsData ? termsData.effectiveDate : null;
        const body = termsData ? termsData.body : null;
        const topics = termsData ? termsData.topics : null;

        const [body1, body2] = splitBody(body);

        return (
            <>
                {
                    termsData &&
                    <>
                        <Navbar />
                        <div className="a4-view">
                            <div className="a4-layout a4-terms">
                                <div className="a4-terms-container">
                                    <div className="a4-terms-page-title" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                        <h1 className="title">{header}</h1>
                                    </div>
                                    <div className="a4-terms-pre" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                        <h4 className="title">Effective date: {effectiveDate}.</h4>
                                        <div className="cols">
                                            <div className="column">
                                                <p><span className="text-primary" dangerouslySetInnerHTML={{ __html: body1 }}></span></p>
                                            </div>
                                            <div className="column">
                                                <p><span className="text-primary" dangerouslySetInnerHTML={{ __html: body2 }}></span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="a4-terms-topics-summary" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                        <h2 className="title">Choose the terms</h2>
                                        <div className="summary-items">
                                            <div className="summary-items-fill"></div>
                                            {
                                                topics.length <= 10 &&
                                                <ul>
                                                    {
                                                        topics.map((topic, index) => {
                                                            return <li key={index}>
                                                               <button topic={topic.name.toLowerCase()} class="summary-items-fill-button" onClick={this.clickTopic}>{topic.name}</button>
                                                            </li>
                                                        })
                                                    }
                                                </ul>
                                            }
                                            {
                                                topics.length > 10 &&
                                                <>
                                                    <ul>
                                                        {
                                                            topics.slice(0, Math.ceil(topics.length / 2)).map((topic, index) => {
                                                                return <li key={index}>
                                                                    <button topic={topic.name.toLowerCase()} class="summary-items-fill-button" onClick={this.clickTopic}>{topic.name}</button>
                                                                </li>
                                                            })
                                                        }
                                                    </ul>
                                                    <ul>
                                                        {
                                                            topics.slice(Math.ceil(topics.length / 2), topics.length).map((topic, index) => {
                                                                return <li key={index}>
                                                                    <button topic={topic.name.toLowerCase()} class="summary-items-fill-button" onClick={this.clickTopic}>{topic.name}</button>
                                                                </li>
                                                            })
                                                        }
                                                    </ul>
                                                </>
                                            }
                                            <div className="summary-items-control">
                                                <svg className="a4-svgsprite -arrow-down text-secondary">
                                                    <ArrowDown fill={bootstrapService.primaryColor} />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="a4-policy-topics" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                        {
                                            topics.map((topic, index) => {
                                                if (topic.sections.length === 1) {
                                                    return <div className="topic" key={index} topic={topic.name.toLowerCase()} ref={div => this.topicRefs[index] = div}>
                                                        <h2 className="topic-title">{topic.name}</h2>
                                                        <div className="cols">
                                                            {
                                                                topic.sections.map((item, itemIndex) => {
                                                                    return <div key={itemIndex}>
                                                                        {
                                                                            item.title &&
                                                                            <h3 className="title">{item.header}</h3>
                                                                        }
                                                                        <span className="description" key={itemIndex} dangerouslySetInnerHTML={{ __html: item.body }}></span>
                                                                    </div>
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                else {
                                                    const [firstItems, secondItems] = splitArray(topic.sections);
                                                    return <div className="topic" key={index} topic={topic.name.toLowerCase()} ref={div => this.topicRefs[index] = div}>
                                                        <h2 className="topic-title">{topic.name}</h2>
                                                        <div className="cols">
                                                            <div className="column">
                                                                {
                                                                    firstItems.map((item, itemIndex) => {
                                                                        return <div key={itemIndex}>
                                                                            {
                                                                                item.title &&
                                                                                <h3 className="title">{item.header}</h3>
                                                                            }
                                                                            <span className="description" key={itemIndex} dangerouslySetInnerHTML={{ __html: item.body }}></span>
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>
                                                            <div className="column">
                                                                {
                                                                    secondItems.map((item, itemIndex) => {
                                                                        return <div key={itemIndex}>
                                                                            {
                                                                                item.title &&
                                                                                <h3 className="title">{item.header}</h3>
                                                                            }
                                                                            <span className="description" dangerouslySetInnerHTML={{ __html: item.body }}></span>
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </>
                }
            </>
        )
    }
}

export default Terms;