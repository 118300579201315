import React from 'react'
import './Requirements.scss'
import $ from 'jquery'
import 'select2'
import { debounce } from '../../utils/debounce'
import { isMobileOnly } from 'react-device-detect'

import Navbar from '../shared/Navbar/Navbar'
import Footer from '../shared/Footer/Footer'
import { ReactComponent as Error } from '../../assets/error.svg'
import { ReactComponent as Social } from '../../assets/social.svg'
import { ReactComponent as Phone } from '../../assets/phone.svg'
import { ReactComponent as World } from '../../assets/world.svg'
import { ReactComponent as Letter } from '../../assets/letter.svg'

import bootstrapService from '../../services/BootstrapService'

class Requirements extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.content = null;
        this.mobileSelect = null;

        // State
        this.state = {
            currentState: this.props.match.params.state,
            searchFilter: null,
        }

        // This Binding
        this.clickState = this.clickState.bind(this);
        this.searchStates = debounce(this.searchStates.bind(this), 400);
    }

    // Lifecycle
    componentDidMount() {
        window.scrollTo(0, 0);
        this.setupSelects();
    }

    componentDidUpdate() {
        this.updateSelects();
        this.updateCurrentState();
    }

    // Helpers
    setupSelects() {
        $('.a4-select select').select2({
            minimumResultsForSearch: -1,
        });

        $('.a4-select select').on("change", (e, shouldIgnore, shouldIgnoreHistory) => {
            if (!shouldIgnore) {
                this.setState({ currentState: e.currentTarget.value });

                if (!shouldIgnoreHistory) {
                    this.props.history.push(`/requirements/${e.currentTarget.value}`);
                }
            }
        });

        $(this.mobileSelect).val(this.state.currentState);
        $(this.mobileSelect).trigger("change", [false, true]);
    }

    updateSelects() {
        if (!$('.a4-select select').first().hasClass("select2-hidden-accessible")) {
            this.setupSelects();
        }
    }

    updateCurrentState() {
        // Back Button Clicked
        window.onpopstate = (e) => {
            if (this.props.match.params.state !== this.state.currentState) {
                this.setState({ currentState: this.props.match.params.state }, () => {
                    $('.a4-select select').val(this.props.match.params.state);
                    $('.a4-select select').trigger("change", [false, true]);
                })
            }
        } 
    }

    // Event Handlers
    clickState(e) {
        const stateName = e.currentTarget.getAttribute('statename');
        this.setState({ currentState: stateName }, () => {
            if (window.scrollY > 300) {
                this.content.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
            
            this.props.history.push(`/requirements/${stateName}`);
            $('.a4-select select').val(stateName);
            $('.a4-select select').trigger("change", true);
        })
    }

    searchStates(e) {
        this.setState({ searchFilter: e.target.value !== "" ? e.target.value.toLowerCase() : null });
    }

    // Component
    render() {
        const requirementsData = bootstrapService.data.landing.pages.find(p => p.name === "requirements");

        const header = requirementsData ? requirementsData.header : null;

        const allRequirements = requirementsData ? requirementsData.requirements : null;
        const requirements = allRequirements && this.state.searchFilter ? allRequirements.filter(r => r.authorityName.toLowerCase().search(this.state.searchFilter) > -1) : allRequirements;
        const currentRequirement = allRequirements ? allRequirements.find(r => r.authorityName.toLowerCase() === this.state.currentState) : null;

        const renderKey = this.state.currentState; // only re-render when state changes

        return (
            <>
                {
                    requirementsData && 
                    <>
                        <Navbar activeLink="requirements" />
                        <div className="a4-view">
                            <div className="a4-layout a4-ce">
                                <div className="a4-ce-container">
                                    <div className="a4-ce-page-title" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                        <h1 className="title">{header}</h1>
                                    </div>
                                    <div className="a4-ce-content" ref={div => this.content = div}>
                                        {
                                            allRequirements.length > 1 && 
                                            <div className="a4-ce-states-panel">
                                                <div className="a4-ce-states-panel-control" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                                    <div className="a4-ce-states-panel-control-label">
                                                        Choose your state
                                                    </div>
                                                    <div className="a4-select">
                                                        <select ref={select => this.mobileSelect = select}>
                                                            {
                                                                allRequirements.map((requirement, index) => {
                                                                    return <option value={requirement.authorityName.toLowerCase()} key={index}>{requirement.authorityName}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="a4-input -search filter-item">
                                                        <input required placeholder="Search" onChange={this.searchStates} />
                                                        <label>Search</label>
                                                        <span className="a4-input-error"></span>
                                                        <svg className="a4-svgsprite -error text-secondary">
                                                            <Error />
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className="a4-ce-states-panel-list" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                                    <div className="a4-ce-states-panel-list-fill"></div>
                                                    <ul>
                                                        {
                                                            requirements.map((requirement, index) => {
                                                                return <li className={requirement.authorityName === (currentRequirement ? currentRequirement.authorityName : null) ? "-active" : ""} key={index} style={{cursor: "pointer"}}>
                                                                    <div statename={requirement.authorityName.toLowerCase()} onClick={this.clickState}>{requirement.authorityName}</div>
                                                                </li>
                                                            })
                                                        }
                                                    </ul>
                                                    {
                                                        this.state.searchFilter && requirements.length === 0 &&
                                                        <div className="a4-ce-states-panel-list-no-results">
                                                            <div>No states matched your search</div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        <div className="a4-ce-info" key={renderKey}>
                                            {
                                                currentRequirement &&
                                                <>
                                                    <div className="a4-ce-article">
                                                        <div className="a4-ce-article-title" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                                            <h2>{currentRequirement.authorityName}</h2>
                                                        </div>
                                                        <div className="a4-ce-article-content" data-aos={isMobileOnly ? undefined : "fade-up"} dangerouslySetInnerHTML={{ __html: currentRequirement.requirementText }}>
                                                        </div>
                                                    </div>
                                                    <div className="a4-ce-contact-card" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                                        <div className="a4-ce-contact-card-title">
                                                            <h4>{currentRequirement.organizationName}</h4>
                                                        </div>
                                                        <div className="a4-ce-contact-card-item">
                                                            <div className="a4-ce-contact-card-item-icon">
                                                                <svg className="a4-svgsprite -social text-secondary">
                                                                    <Social fill={bootstrapService.primaryColor} />
                                                                </svg>
                                                            </div>
                                                            <div className="a4-ce-contact-card-item-info">
                                                                <div className="a4-ce-contact-card-item-label">Organization:</div>
                                                                <div className="a4-ce-contact-card-item-text">{currentRequirement.organizationName}</div>
                                                            </div>
                                                        </div>
                                                        <div className="a4-ce-contact-card-item">
                                                            <div className="a4-ce-contact-card-item-icon">
                                                                <svg className="a4-svgsprite -phone text-secondary">
                                                                    <Phone stroke={bootstrapService.primaryColor} />
                                                                </svg>
                                                            </div>
                                                            <div className="a4-ce-contact-card-item-info">
                                                                <div className="a4-ce-contact-card-item-label">Phone:</div>
                                                                <a href={`tel:${currentRequirement.organizationPhone}`} className="a4-ce-contact-card-item-text">{currentRequirement.organizationPhone}</a>
                                                            </div>
                                                        </div>
                                                        <div className="a4-ce-contact-card-item">
                                                            <div className="a4-ce-contact-card-item-icon">
                                                                <svg className="a4-svgsprite -world text-secondary">
                                                                    <World fill={bootstrapService.primaryColor} />
                                                                </svg>
                                                            </div>
                                                            <div className="a4-ce-contact-card-item-info">
                                                                <div className="a4-ce-contact-card-item-label">Website:</div>
                                                                <a href={currentRequirement.organizationUrl} className="a4-ce-contact-card-item-text" target="_blank" rel="noreferrer">{currentRequirement.organizationUrl}</a>
                                                            </div>
                                                        </div>
                                                        {
                                                            currentRequirement.organizationEmail &&
                                                            <div className="a4-ce-contact-card-item">
                                                                <div className="a4-ce-contact-card-item-icon">
                                                                    <svg className="a4-svgsprite -letter text-secondary">
                                                                        <Letter fill={bootstrapService.primaryColor} />
                                                                    </svg>
                                                                </div>
                                                                <div className="a4-ce-contact-card-item-info">
                                                                    <div className="a4-ce-contact-card-item-label">Email:</div>
                                                                    <a href={`mailto:${currentRequirement.organizationEmail}`} className="a4-ce-contact-card-item-text">{currentRequirement.organizationEmail}</a>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <Footer />
                            </div>
                        </div>
                    </>
                }
            </>
        )
    }
}

export default Requirements;