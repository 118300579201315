import React from 'react'
import './Payment.scss'
import { Link } from "react-router-dom"
import $ from 'jquery'
import 'select2'
import gsap from 'gsap'
import { isMobileOnly } from 'react-device-detect'

import Navbar from '../shared/Navbar/Navbar'
import Footer from '../shared/Footer/Footer'
import BubbleButton from '../shared/BubbleButton/BubbleButton'
import { ReactComponent as Error } from '../../assets/error.svg'

import formService from '../../services/FormService'
import paymentService from '../../services/PaymentService'
import memberService from '../../services/MemberService'
import recaptchaService from '../../services/RecaptchaService'
import scheduleService from '../../services/ScheduleService'
import bootstrapService from '../../services/BootstrapService'

class Payment extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        //Payment options
        this.paymentOptions = bootstrapService.getPaymentOptions();
        if (this.paymentOptions) {
            this.useCreditCard = this.paymentOptions.useCreditCard;
            this.useCreditCardSandbox = this.paymentOptions.useCreditCardSandbox;
            this.usePayPal = this.paymentOptions.usePayPal;
            this.usePayPalSandbox = this.paymentOptions.usePayPalSandbox;
            this.useOffline = this.paymentOptions.useOffline;
            this.numPaymentMethods = (this.useCreditCard ? 1 : 0) + (this.usePayPal ? 1 : 0) + (this.useOffline ? 1 : 0);
        }

        // Refs
        this.form = null;
        this.paymentSwitcher = null;
        this.paymentSwitch = null;
        this.paymentInputs = null;
        this.paymentParagraph = null;

        // This Binding
        this.clickSwitch = this.clickSwitch.bind(this);
        this.getSandboxTicket = this.getSandboxTicket.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    // Lifecyle
    componentDidMount() {
        window.scrollTo(0, 0);
        this.setupSelects();
        this.setupSwitcher();
    }

    componentDidUpdate() {
        this.setupSelects();
    }

    // Helpers
    setupSwitcher() {
        if (this.paymentSwitcher) {
            const paymentSelections = this.paymentSwitcher.querySelectorAll(".a4-payment-form-selection");

            if (paymentService.paymentType === "cc") {
                paymentSelections[0].classList.add("-active");
                this.paymentSwitch.style.width = paymentSelections[0].offsetWidth + 5 + "px";
                this.paymentSwitch.style.left = paymentSelections[0].offsetLeft + 40 + "px";
            }
            else if (paymentService.paymentType === "pp") {
                paymentSelections[1].classList.add("-active");
                this.paymentSwitch.style.width = paymentSelections[1].offsetWidth + 5 + "px";
                this.paymentSwitch.style.left = paymentSelections[1].offsetLeft + 40 + "px";
            }
            else {
                paymentSelections[2].classList.add("-active");
                this.paymentSwitch.style.width = paymentSelections[2].offsetWidth + 5 + "px";
                this.paymentSwitch.style.left = paymentSelections[2].offsetLeft + "px";
            }

            this.togglePaymentInputs(paymentService.paymentType);
        }
    }

    setupSelects() {
        $('.a4-select select').select2({
            minimumResultsForSearch: -1,
        });

        $('.a4-payment-form-btn-group-user select, .a4-payment-form-btn-group-payment select').on("change", (e, shouldIgnore) => {
            if (!shouldIgnore) {
                const selectedSwitch = $(this.paymentSwitcher).find(".a4-payment-form-selection").filter(`[data-value="${e.currentTarget.value}"]`)[0];

                if (selectedSwitch) {
                    this.setSwitchActive(selectedSwitch);
                    this.togglePaymentInputs(e.currentTarget.value);
                    paymentService.paymentType = e.currentTarget.value;
                }
            }
        });
    }

    setSwitchActive(selection) {
        formService.hideAllErrors(this.form);

        $(selection).siblings().removeClass("-active");
        selection.classList.add("-active");

        gsap.to(this.paymentSwitch, {
            width: selection.offsetWidth,
            left: selection.offsetLeft + 40,
            duration: 0.2,
        });
    }

    togglePaymentInputs(value) {
        const paymentInputs = $(this.paymentInputs).find(".a4-input");

        if (value === "cc") {
            $(this.paymentParagraph).hide();
            $(this.paymentInputs).show(0, () => {
                this.enableForm(paymentInputs);
            });

        } else {
            this.disableForm(paymentInputs);

            if (value === "pp") {
                this.paymentParagraph.innerText = "You will be redirected to PayPal to complete your payment.";
            }
            else {
                this.paymentParagraph.innerText = "You will be provided with instructions to provide your payment information.";
            }

            $(this.paymentInputs).hide(200);
            $(this.paymentParagraph).show();
        }
    }

    disableForm(paymentInputs) {
        paymentInputs.each((index, input) => {
            input.classList.add("-disabled");
        });
    }

    enableForm(paymentInputs) {
        paymentInputs.each((index, input) => {
            input.classList.remove("-disabled");
        });
    }

    // Event Handlers
    clickSwitch(e) {
        if (!e.currentTarget.classList.contains("-active")) {
            this.setSwitchActive(e.currentTarget);
            this.togglePaymentInputs(e.currentTarget.dataset.value);

            const select = $(e.currentTarget).parent().find("select");
            select.val(e.currentTarget.dataset.value);
            select.trigger("change", true);

            paymentService.paymentType = e.currentTarget.dataset.value;
        }
    }

    getSandboxTicket(e) {
        e.preventDefault();

        const useSandbox =
            (paymentService.paymentType === "cc" && this.useCreditCardSandbox) ||
            (paymentService.paymentType === "pp" && this.usePayPalSandbox);

        if (useSandbox) {
            if (e.ctrlKey || e.metaKey) {

                const inputs = this.form.querySelectorAll('.a4-input');

                paymentService.getSandboxTicket()
                    .then(json => {
                        if (json.succeeded) {
                            if (paymentService.paymentType === "cc") {
                                inputs[0].querySelector("input, textarea").value = "4007000000027"
                                inputs[1].querySelector("input, textarea").value = "12"
                                inputs[2].querySelector("input, textarea").value = "2025"
                                inputs[3].querySelector("input, textarea").value = "123"
                            }
                        }
                        else {
                            console.log(json.errors);
                        }
                    })
            }
        }
    }

    submitForm(e) {
        e.preventDefault();

        if (paymentService.paymentType === "cc") {
            this.submitCreditCard();
        }
        else if (paymentService.paymentType === "pp") {
            this.submitPayPal();
        }
        else if (paymentService.paymentType === "offline") {
            this.submitCheckOrPhone();
        }
    }

    // Payment Methods
    submitCreditCard() {
        const isFormValid = formService.validate(this.form);
        if (!isFormValid) return;

        formService.showLoadingButton(this.form);

        const creditCardNo = this.form.card.value;
        const expirationMonth = this.form.mm.value;
        const expirationYear = this.form.yyyy.value;
        const securityCode = this.form.cvv.value;

        const creditCard = { creditCardNo, expirationMonth, expirationYear, securityCode };
        paymentService.creditCard = creditCard;

        recaptchaService.getToken("buy")
            .then(token => {
                let buyRequest = { recaptchaToken: token };

                paymentService.buyItemCreditCard(buyRequest)
                    .then(json => {
                        if (json.succeeded) {
                            memberService.goToMembers();
                        }
                        else {
                            formService.hideAllErrors(this.form);
                            formService.hideLoadingButton(this.form);
                            formService.showFormError(this.form, json.errors[0]);
                        }
                    })
            })
    }

    submitPayPal() {
        formService.clearAllInputs(this.form);
        formService.showLoadingButton(this.form);

        recaptchaService.getToken("buy")
            .then(token => {
                let buyRequest = { recaptchaToken: token };

                paymentService.buyItemPayPal(buyRequest)
                    .then(json => {
                        if (json.succeeded) {
                            let token = json.result.orderRequest.paymentRequests[0].token
                            if (token) {
                                //PayPal payment handler for PayPal
                                if (paymentService.sandboxTicket) {
                                    window.location.href = `https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_express-checkout&useraction=commit&token=${token}`;
                                }
                                else {
                                    window.location.href = `https://www.paypal.com/cgi-bin/webscr?cmd=_express-checkout&useraction=commit&token=${token}`;
                                }
                            }
                            else {
                                //Authorize.net payment handler for PayPal
                                let secureAcceptanceUrl = json.result.orderRequest.paymentRequests[0].secureAcceptanceUrl
                                if (secureAcceptanceUrl) {
                                    window.location.href = secureAcceptanceUrl;
                                }
                            }
                        }
                        else {
                            console.log(json.errors)
                            formService.hideLoadingButton(this.form);
                            this.paymentParagraph.innerText = "PayPal payment unsuccessful. Please try again or use another payment method.";
                        }
                    })
            });
    }

    submitCheckOrPhone() {
        formService.clearAllInputs(this.form);
        formService.showLoadingButton(this.form);

        recaptchaService.getToken("buy")
            .then(token => {
                let buyRequest = { recaptchaToken: token };

                paymentService.buyItemOffline(buyRequest)
                    .then(json => {
                        if (json.succeeded) {
                            memberService.goToMembers();
                        }
                        else {
                            console.log(json.errors)
                            formService.hideLoadingButton(this.form);
                            this.paymentParagraph.innerText = "Offline payment unsuccessful. Please try again or use another payment method.";
                        }
                    })
            });
    }

    // Component
    render() {
        // Go Home If No Payment Data
        if (!paymentService.item || !paymentService.member) {
            if (paymentService.group) {
                this.props.history.push("/complete-group");
            }
            else {
                this.props.history.push("/buy");
            }
        }

        const title = paymentService.item ? paymentService.item.title : "";
        const schedule = paymentService.schedule ? `${new Date(paymentService.schedule.startDate).toLocaleDateString("en-US", { weekday: 'long', month: 'short', day: 'numeric' })}, ${new Date(paymentService.schedule.startDate).toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" })} - ${new Date(paymentService.schedule.endDate).toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" })} (${scheduleService.getTimeZone()})` : null;
        const price = paymentService.item ? (paymentService.item.price * paymentService.item.quantity).toFixed(2) : "";

        const creditCardLabel = this.numPaymentMethods === 3 ? "Credit Card" : "Pay With Credit Card";
        const payPalLabel = this.numPaymentMethods === 3 ? "PayPal" : "Pay With PayPal";
        
        return (
            <>
                <Navbar />
                <div className="a4-view">
                    <div className="a4-layout a4-payment">
                        <section className="a4-payment-container">
                            <div className="a4-payment-content" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                <header className="a4-payment-title">
                                    <h1>
                                        {title}
                                    </h1>
                                    {
                                        schedule &&
                                        <h3>
                                            {schedule}
                                        </h3>
                                    }
                                </header>
                                <form className="a4-payment-form" ref={form => this.form = form}>
                                    <div className="a4-payment-form-group">
                                        <div className="a4-payment-form-group-switcher-container -payment" ref={div => this.paymentSwitcher = div}>
                                            <div className="a4-payment-form-btn-group a4-payment-form-btn-group-payment">
                                                <div className="a4-select sm_hidden">
                                                    <select name="payment_type" defaultValue="cc">
                                                    {
                                                        this.useCreditCard && 
                                                        <option value="cc">Credit Card</option>
                                                    }
                                                    {
                                                        this.usePayPal && 
                                                        <option value="pp">PayPal</option>
                                                    }
                                                    {
                                                        this.useOffline && 
                                                        <option value="offline">Check or Phone</option>
                                                    }
                                                    </select>
                                                </div>
                                                {
                                                    this.useCreditCard &&
                                                    <div className="a4-payment-form-selection hidden sm_block" type="button" data-value="cc" onClick={this.clickSwitch}>
                                                        {creditCardLabel}
                                                    </div>
                                                }  
                                                {
                                                    this.usePayPal &&
                                                    <div className="a4-payment-form-selection hidden sm_block" type="button" data-value="pp" onClick={this.clickSwitch}>
                                                        {payPalLabel}
                                                    </div>
                                                }
                                                {
                                                    this.useOffline &&
                                                    <div className="a4-payment-form-selection hidden sm_block" type="button" data-value="offline" onClick={this.clickSwitch}>
                                                        Check or Phone
                                                    </div>
                                                }
                                            </div>
                                            <div className="a4-switcher" ref={div => this.paymentSwitch = div}></div>
                                        </div>
                                        <div className="a4-inputs" ref={div => this.paymentInputs = div}>
                                            <div className="a4-input">
                                                <input name="card" placeholder="Card No" required />
                                                <label htmlFor="card">Card No</label>
                                                <span className="a4-input-error" ref={span => this.cardError = span}></span>
                                                <svg className="a4-svgsprite -error text-secondary">
                                                    <Error />
                                                </svg>
                                            </div>
                                            <div className="a4-payment-form-small-input-group">
                                                <div className="a4-input">
                                                    <input name="mm" placeholder="MM" required />
                                                    <label htmlFor="mm">MM</label>
                                                    <span className="a4-input-error"></span>
                                                    <svg className="a4-svgsprite -error text-secondary">
                                                        <Error />
                                                    </svg>
                                                </div>
                                                <div className="a4-input">
                                                    <input name="yyyy" placeholder="YYYY" required />
                                                    <label htmlFor="yyyy">YYYY</label>
                                                    <span className="a4-input-error"></span>
                                                    <svg className="a4-svgsprite -error text-secondary">
                                                        <Error />
                                                    </svg>
                                                </div>
                                                <div className="a4-input">
                                                    <input name="cvv" placeholder="CVV" type="password" required />
                                                    <label htmlFor="cvv">CVV</label>
                                                    <span className="a4-input-error"></span>
                                                    <svg className="a4-svgsprite -error text-secondary">
                                                        <Error />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="a4-form-error"></p>
                                        <p className="a4-payment-form-paragraph" ref={p => this.paymentParagraph = p}></p>
                                    </div>
                                    <BubbleButton className="a4-form-submit -primary -fixed" type="button" onClick={this.submitForm} formNoValidate>
                                        Buy Now &nbsp; ${price}
                                    </BubbleButton>
                                    <div className="a4-payment-form-terms" onClick={this.getSandboxTicket}>
                                        By clicking Buy Now you agree to our &nbsp;
                                        <Link to="/terms-and-conditions" className="block sm_inline">
                                            <b>Terms of Use</b>
                                        </Link>
                                    </div>
                                </form>
                            </div>
                        </section>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}

export default Payment;