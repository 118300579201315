class ScheduleService {

    getDay(date) {
        return new Date(date).getDate();
    }

    getMonth(date) {
        return new Date(date).getMonth();
    }

    getYear(date) {
        return new Date(date).getFullYear();
    }

    getFirstDayOfWeek(month, year) {
        return new Date(year, month, 1).getDay();
    }

    getLastDayOfWeek(month, year) {
        return new Date(year, month, this.getDaysInMonth(month, year)).getDay();
    }

    getDaysInMonth(month, year) {
        return new Date(year, month + 1, 0).getDate();
    }

    getDaysInPreviousMonth(month, year) {
        return new Date(year, month, 0).getDate();
    }

    getDaysInNextMonth(month, year) {
        return new Date(year, month + 2, 0).getDate();
    }

    getTimeZone() {
        const today = new Date();
        const short = today.toLocaleDateString(undefined);
        const full = today.toLocaleDateString(undefined, { timeZoneName: 'short' });

        // Trying to remove date from the string in a locale-agnostic way
        const shortIndex = full.indexOf(short);
        if (shortIndex >= 0) {
            const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);
            
            // by this time `trimmed` should be the timezone's name with some punctuation -
            // trim it from both sides
            return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');

        } else {
            // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
            return full;
        }
    }
}

const scheduleService = new ScheduleService();
export default scheduleService;