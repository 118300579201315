import React from 'react'
import './Subscribe.scss'
import { isMobileOnly } from 'react-device-detect'

import BubbleButton from '../BubbleButton/BubbleButton'
import { ReactComponent as Error } from '../../../assets/error.svg'
import { ReactComponent as CheckMark } from '../../../assets/check-mark.svg'

import bootstrapService from '../../../services/BootstrapService'
import formService from '../../../services/FormService'
import memberService from '../../../services/MemberService'
import recaptchaService from '../../../services/RecaptchaService'

class Subscribe extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.form = null;
        this.successMessage = null;

        // This Binding
        this.submitForm = this.submitForm.bind(this);
    }

    // Event Handlers
    submitForm(e) {
        e.preventDefault();

        const isFormValid = formService.validate(this.form);

        if(!isFormValid) return;

        formService.showLoadingButton(this.form);

        const email = e.target.email.value.trim();

        recaptchaService.getToken("subscribe")
        .then(token => {
            let subscribeRequirement = {email, recaptchaToken: token};

            memberService.subscribe(subscribeRequirement)
            .then((json) => {
                if (json.succeeded) {
                    this.successMessage.classList.add("-visible");
                }
                else {
                    formService.hideAllErrors(this.form);
                    formService.hideLoadingButton(this.form);
                    formService.showFormError(this.form, json.errors[0]);
                }
            });
        });
    }

    // Component
    render() {
        const subscribeData = bootstrapService.data.landing.sections.find(s => s.name === "newsletter");

        const headers = subscribeData ? subscribeData.headers : null;
        const body = subscribeData ? subscribeData.body : null;

        return (
            <>
                {
                    subscribeData &&
                    <section className="a4-special-offers">
                        <div className="a4-special-offers-container">
                            <div className="a4-special-offers-content">
                                <div className="a4-special-offers-info">
                                    <div className="a4-special-offers-title" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                        <h2>{headers[0]} <span className="text-primary">{headers[1]}</span> {headers[2]}</h2>
                                    </div>
                                    <div className="a4-special-offers-description" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                        <p>{body}</p>
                                    </div>
                                </div>
                                <form className="a4-special-offers-form" data-aos={isMobileOnly ? undefined : "fade-up"} ref={form => this.form = form} onSubmit={this.submitForm}>
                                    <div className="a4-special-offers-form-filler" aria-hidden={true}></div>
                                    <section className="a4-special-offers-form-section">
                                        <div className="a4-input">
                                            <input name="email" type='email' required placeholder="E-mail address" />
                                            <label htmlFor="email">E-mail address</label>
                                            <span className="a4-input-error">Enter a valid e-mail address</span>
                                            <svg className="a4-svgsprite -error text-secondary">
                                                <Error />
                                            </svg>
                                        </div>
                                        <p className="a4-form-error"></p>
                                        <div className="a4-special-offers-form-control">
                                            <BubbleButton className="a4-form-submit -primary" type="submit" formNoValidate>
                                                <b>Subscribe</b>
                                            </BubbleButton>
                                        </div>
                                        <div className="a4-special-offers-sub-anytime">
                                            <p>You can unsubscribe at any time.</p>
                                        </div>
                                    </section>
                                    <section className="a4-special-offers-form-section -success" ref={section => this.successMessage = section}> 
                                        <figure>
                                            <svg className="a4-svgsprite text-secondary">
                                                <CheckMark stroke={bootstrapService.primaryColor} />
                                            </svg>
                                        </figure>
                                        <h3>Thank you!</h3>
                                        <p>Your subscription has been successfully issued.</p>
                                    </section>
                                </form>
                            </div>
                        </div>
                    </section>
                }
            </>
        )
    }
}

export default Subscribe;