import React from 'react'
import './Approvals.scss'
import { Link } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'

import Navbar from '../shared/Navbar/Navbar'
import Subscribe from '../shared/Subscribe/Subscribe'
import Footer from '../shared/Footer/Footer'
import courseImage from '../../assets/course-plug.svg'

import bootstrapService from '../../services/BootstrapService'

class Approvals extends React.Component {
    // Lifecycle
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    // Component
    render() {
        const approvalsData =  bootstrapService.data.landing.pages.find(p => p.name === "approvals");

        const header = approvalsData ? approvalsData.header : null;
        const imageUrl = approvalsData ? approvalsData.imageUrl : null;
        const orgApprovals = approvalsData ? approvalsData.organizationApprovals : null;
        const courseApprovals = approvalsData ? approvalsData.courseApprovals : null;

        return (
            <>
                {
                    approvalsData &&
                    <>
                        <Navbar activeLink="approvals" />
                        <div className="a4-view">
                            <div className="a4-layout a4-approvals">
                                <div className="a4-approvals-container">
                                    <section className="a4-approvals-main">
                                        <div className="a4-approvals-main-container">
                                            <div className="a4-approvals-main-content">
                                                <div className="a4-approvals-main-illustration" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-delay="0" data-aos-anchor-placement="top-bottom">
                                                    <img src={imageUrl} alt="" />
                                                </div>
                                                <div className="a4-approvals-main-title" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-delay="100" data-aos-anchor-placement="top-bottom">
                                                    <h1>{header}</h1>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section className="a4-approvals-app-and-acc">
                                        <div className="a4-approvals-app-and-acc-container">
                                            <div className="a4-approvals-app-and-acc-content">
                                                {
                                                    orgApprovals && orgApprovals.length > 0 &&
                                                    <div className="a4-approvals-app-and-acc-topic">
                                                        <div className="a4-approvals-app-and-acc-topic-title" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-delay="0" data-aos-anchor-placement="top-bottom">
                                                            <h2>Approvals & Accreditations</h2>
                                                        </div>
                                                        <section className="a4-approvals-list">
                                                            {
                                                                orgApprovals.map((approval, index) => {
                                                                    return <a href={approval.url} target="_blank" rel="noreferrer" className="a4-approvals-list-item -highlighted" key={index}>
                                                                        <div className="a4-approvals-list-item-image" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                                                            <img src={approval.imageUrl ? approval.imageUrl : courseImage} alt="" />
                                                                        </div>
                                                                        <div className="a4-approvals-list-item-block">
                                                                            <div className="a4-approvals-list-item-block-title" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                                                                <h3>{approval.header}</h3>
                                                                            </div>
                                                                            <div className="a4-approvals-list-item-block-description" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                                                                <p dangerouslySetInnerHTML={{ __html: approval.body}}></p>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                })
                                                            }
                                                        </section>
                                                    </div>
                                                }
                                                {
                                                    courseApprovals && courseApprovals.length > 0 &&
                                                    <div className="a4-approvals-app-and-acc-topic">
                                                        <div className="a4-approvals-app-and-acc-topic-title" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-delay="0" data-aos-anchor-placement="top-bottom">
                                                            <h2>Course Specific Approvals</h2>
                                                        </div>
                                                        <section className="a4-approvals-list">
                                                            {
                                                                courseApprovals.map((approval, index) => {
                                                                    return <a href={approval.url} target="_blank" rel="noreferrer" className="a4-approvals-list-item -highlighted" key={index}>
                                                                        <div className="a4-approvals-list-item-image" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                                                            <img src={approval.imageUrl ? approval.imageUrl : courseImage} alt="" />
                                                                        </div>
                                                                        <div className="a4-approvals-list-item-block">
                                                                            <div className="a4-approvals-list-item-block-title" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                                                                <h3>{approval.header}</h3>
                                                                            </div>
                                                                            <div data-aos={isMobileOnly ? undefined : "fade-up"}>
                                                                                <div className="a4-approvals-list-item-block-description">
                                                                                    <p dangerouslySetInnerHTML={{ __html: approval.body}}></p>
                                                                                </div>
                                                                                <div className="a4-approvals-list-item-block-control">
                                                                                    <Link to={`/course/${approval.features.find(f => f.name === "courseId").value}`}>
                                                                                        <span className="text-primary">Course Details</span>
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                })
                                                            }
                                                        </section>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                <Subscribe />
                                <Footer />
                            </div>
                        </div>
                    </>
                }   
            </>
        )
    }
}

export default Approvals;