import React from 'react'
import { Link } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'

import Navbar from '../shared/Navbar/Navbar'
import Footer from '../shared/Footer/Footer'
import BubbleButton from '../shared/BubbleButton/BubbleButton'

import bootstrapService from '../../services/BootstrapService'
import memberService from '../../services/MemberService'

class ConfirmEmail extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // State
        this.state = {
            errorBody: "Your email address could not be verified."
        }
    }

    // Lifecycle
    componentDidMount() {
        window.scrollTo(0, 0);
        this.setBootstrapCallback();
    }

    // Helpers
    setBootstrapCallback() {
        bootstrapService.setCallback(() => {
            return this.validateToken();
        })
    }

    validateToken() {
        const token = this.props.match.params.token;

        return memberService.validateConfirmEmailToken(token)
        .then(json => {
            if (json.succeeded) {
                if (json.result.status === 0) {
                    this.forceUpdate();
                }
                else if (json.result.status === 1) {
                    this.setState({ errorBody: "Your email address could not be verified.<br></br>Token is invalid." });
                }
                else if (json.result.status === 2) {
                    this.setState({ errorBody: "Your email address could not be verified.<br></br>Token is expired." });
                }
                else if (json.result.status === 3) {
                    this.setState({ errorBody: "Your email address could not be verified.<br></br>Token is expired. A new email has been sent." });
                }
                else if (json.result.status === 4) {
                    this.setState({ errorBody: "Your email address could not be verified.<br></br>Confirm email is not required." });
                }
            }
            else {
                this.setState({ errorBody: `Your email address could not be verified.<br></br>${json.errors[0]}` });
            }
        })
    }

    // Component
    render() {
        const tokenValidated = memberService.confirmEmailValidated;
        const errorBody = this.state.errorBody;

        return (
            <>
                <Navbar />
                <div className="a4-view">
                    <main className="a4-layout a4-auth">
                        {
                            tokenValidated &&
                            <div className="a4-auth-container">
                                <section className="a4-auth-item-container">
                                    <div className="a4-auth-item-shadow"></div>
                                    <div className="a4-auth-item-content confirm-email" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                        <h1 className="a4-auth-item-title"><span className="text-primary">Email</span> has been verified!</h1>
                                        <p>Thank you for verifying your email address.<br></br>Please login using the button below.</p>
                                        <form className="a4-auth-form" onSubmit={this.submitForm}>
                                            <section className="a4-auth-form-control">
                                                <Link to="/login">
                                                    <BubbleButton className="-primary" type="submit" formNoValidate>
                                                        <b>Login to account</b>
                                                    </BubbleButton>
                                                </Link>
                                            </section>
                                        </form>
                                    </div>
                                </section>
                                <aside className="a4-auth-additional" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-delay='100'>
                                    <span>
                                        Don’t have an Account? &nbsp;
                                        <Link to="/register" className="text-primary">Register now!</Link>
                                    </span>
                                </aside>
                            </div>
                        }
                        {
                            !tokenValidated &&
                            <div className="a4-auth-container">
                                <section className="a4-auth-item-container">
                                    <div className="a4-auth-item-shadow"></div>
                                    <div className="a4-auth-item-content confirm-email" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                        <h1 className="a4-auth-item-title">Confirm Email <span className="text-primary">Error</span></h1>
                                        <p dangerouslySetInnerHTML={{ __html: errorBody}}></p>
                                        <form className="a4-auth-form" onSubmit={this.submitForm}>
                                            <section className="a4-auth-form-control">
                                                <Link to="/help-and-support">
                                                    <BubbleButton className="-primary" type="submit" formNoValidate>
                                                        <b>Get Help</b>
                                                    </BubbleButton>
                                                </Link>
                                            </section>
                                        </form>
                                    </div>
                                </section>
                                <aside className="a4-auth-additional" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-delay='100'>
                                    <span>
                                        Don’t have an Account? &nbsp;
                                        <Link to="/register" className="text-primary">Register now!</Link>
                                    </span>
                                </aside>
                            </div>
                        }
                        <Footer />
                    </main>
                </div>
            </>
        )
    }
}

export default ConfirmEmail;