import React from 'react'
import './AffiliatedSites.scss'
import { isMobileOnly } from 'react-device-detect'

import Navbar from '../shared/Navbar/Navbar'
import Subscribe from '../shared/Subscribe/Subscribe'
import Footer from '../shared/Footer/Footer'
import placeholderImage from '../../assets/course-plug.svg'

import bootstrapService from '../../services/BootstrapService'

class AffiliatedSites extends React.Component {

    // Lifecycle
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    // Component
    render() {
        const affiliatedData = bootstrapService.data.landing.pages.find(p => p.name === "our-sites");

        const header = affiliatedData ? affiliatedData.header : null;
        const body = affiliatedData ? affiliatedData.bodyText : null;
        const sites = affiliatedData ? affiliatedData.sites : null;

        return (
            <>
                {
                    affiliatedData &&
                    <>
                        <Navbar />
                        <div className="a4-view">
                            <div className="a4-layout a4-affiliated-sites">
                                <div className="a4-affiliated-sites-container">
                                    <div className="a4-affiliated-sites-page-title" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                        <h1 className="title">{header}</h1>
                                        <p className="description">{body}</p>
                                    </div>
                                    <div className="a4-affiliated-sites-list" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                        <ul className="a4-affiliated-sites-list-items">
                                            {
                                                sites.map((site, index) => {
                                                    return <li className="a4-affiliated-sites-list-items-item" key={index}>
                                                        <div className="a4-affiliated-sites-list-items-item-logo">
                                                            <a href={site.url} target="_blank" rel="noreferrer">
                                                                <img src={site.imageUrl ? site.imageUrl : placeholderImage} alt="" />
                                                            </a>
                                                        </div>
                                                        <p className="a4-affiliated-sites-list-items-item-text">{site.body}</p>
                                                    </li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <Subscribe />
                                <Footer />
                            </div>
                        </div>
                    </>
                }
            </>
        )
    }
}

export default AffiliatedSites;