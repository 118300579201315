import React from 'react'
import './Spacer.scss'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { isMobileOnly } from 'react-device-detect'

class Spacer extends React.Component {
    // Component
    render() {
        const { imageUrl, imageBackgroundUrl } = this.props.data;
        const type = this.props.type;
        const backgroundColor = this.props.backgroundColor;

        return (
            <section className={`a4-spacer -${type}`}>
                <div className="a4-spacer-container">
                    <div className={"a4-spacer-filler " + backgroundColor}></div>
                    <div className="a4-spacer-content">
                        <div className="a4-spacer-content-wrapper">
                            {
                                imageBackgroundUrl &&
                                <img className="a4-spacer-content-background" src={imageBackgroundUrl} alt="" />
                            }
                            <img src={imageUrl} data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-easing="ease-out-quad" alt="" onLoad={() => ScrollTrigger.refresh()}/>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Spacer;