import React from 'react'
import Navbar from '../shared/Navbar/Navbar'
import Footer from '../shared/Footer/Footer'
import BubbleButton from '../shared/BubbleButton/BubbleButton'
import { ReactComponent as Error } from '../../assets/error.svg'
import { isMobileOnly } from 'react-device-detect'

import formService from '../../services/FormService'
import memberService from '../../services/MemberService'
import recaptchaService from '../../services/RecaptchaService'

class ResetPassword extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.form = null;

        // This Binding
        this.submitForm = this.submitForm.bind(this);
    }

    // Lifecycle
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    
    // Event Handlers
    submitForm(e) {
        e.preventDefault();

        const isFormValid = formService.validate(this.form);

        const token = this.props.match.params.token;
        const password = e.target.password.value;
        const confirm = e.target.confirm.value;

        if (password !== confirm) {
            formService.showInputError(this.form, "confirm", "This password does not match");
            formService.hideLoadingButton(this.form);
            return;
        }

        if(!isFormValid) return;

        formService.showLoadingButton(this.form);

        let resetPasswordRequest = {token, password}

        recaptchaService.getToken("resetPassword")
        .then(token => {
            resetPasswordRequest.recaptchaToken = token;

            memberService.resetPassword(resetPasswordRequest)
            .then(json => {
                if (json.succeeded) {
                    memberService.goToMembers();
                }
                else {
                    formService.hideAllErrors(this.form);
                    formService.hideLoadingButton(this.form);
                    formService.showFormError(this.form, json.errors[0]);
                }
            });
        });
    }

    // Component
    render() {
        return (
            <>
                <Navbar />
                <div className="a4-view">
                    <main className="a4-layout a4-auth">
                        <div className="a4-auth-container -login">
                            <section className="a4-auth-item-container">
                                <div className="a4-auth-item-shadow"></div>
                                <div className="a4-auth-item-content" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                    <h1 className="a4-auth-item-title"><span className="text-primary">Reset</span> your password</h1>
                                    <p>Enter your new password.</p>
                                    <form className="a4-auth-form" ref={form => this.form = form} onSubmit={this.submitForm}>
                                        <div className="a4-input">
                                            <input name="password" placeholder="Password" required type="password" />
                                            <label htmlFor="password">New Password</label>
                                            <span className="a4-input-error">Enter a valid password</span>
                                            <svg className="a4-svgsprite -error text-secondary">
                                                <Error />
                                            </svg>
                                        </div>
                                        <div className="a4-input">
                                            <input name="confirm" placeholder="Re-enter New Password" required type="password" />
                                            <label htmlFor="password">Re-enter New Password</label>
                                            <span className="a4-input-error">Enter a valid password</span>
                                            <svg className="a4-svgsprite -error text-secondary">
                                                <Error />
                                            </svg>
                                        </div>
                                        <p className="a4-form-error"></p>
                                        <section className="a4-auth-form-control">
                                            <BubbleButton className="a4-form-submit -primary" type="submit" formNoValidate>
                                                <b>Reset password</b>
                                            </BubbleButton>
                                        </section>
                                    </form>
                                </div>
                            </section>
                            <aside className="a4-auth-additional" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-delay='100'>
                            </aside>
                        </div>
                        <Footer />
                    </main>
                </div>
            </>
        )
    }
}

export default ResetPassword;