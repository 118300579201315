import React from 'react'
import {ReactTitle} from 'react-meta-tags'
import './Home.scss'

import Navbar from '../shared/Navbar/Navbar'
import Exposition from './Exposition/Exposition'
import List from './List/List'
import CourseItem from './List/Items/CourseItem'
import HowItWorksItem from './List/Items/HowItWorksItem'
import WhyUsItem from './List/Items/WhyUsItem'
import SingleCourse from './SingleCourse/SingleCourse'
import AppStore from './AppStore/AppStore'
import Spacer from './Spacer/Spacer'
import TryForFree from './TryForFree/TryForFree'
import Plans from './Plans/Plans'
import Reviews from '../shared/Reviews/Reviews'
import Subscribe from '../shared/Subscribe/Subscribe'
import Footer from '../shared/Footer/Footer'
import GroupsModal from './GroupsModal/GroupsModal'

import bootstrapService from '../../services/BootstrapService'

class Home extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.navbar = null;
        this.groupPlanModal = null;
        this.tryForFreeSection = null;

        // This Binding
        this.clickTryForFree = this.clickTryForFree.bind(this);
        this.toggleGroupPlanModal = this.toggleGroupPlanModal.bind(this);
        this.goToCreateGroup = this.goToCreateGroup.bind(this);
        this.goToJoinGroup = this.goToJoinGroup.bind(this);
    }

    // Lifecycle
    componentDidMount() {
        this.setupScroll();
    }

    // Helpers
    setupScroll() {
        if (this.props.location.state && this.props.location.state.tryForFree) {
            setTimeout(() => {
                if (this.tryForFreeSection) {
                    this.tryForFreeSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }, 300);
        }
        else {
            window.scrollTo(0, 0);
        }
    }

    // Event Handlers
    clickTryForFree() {
        this.tryForFreeSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    toggleGroupPlanModal() {
        this.groupPlanModal.toggleModal();
        this.navbar.toggleOverflowForModal();
    }

    goToCreateGroup(e) {
        this.toggleGroupPlanModal();
        this.props.history.push("/pricing/group-plans");
    }

    goToJoinGroup(groupCode) {
        this.toggleGroupPlanModal();
        this.props.history.push(`/register/${groupCode}`);
    }

    // Component
    render() {
        const homeData = bootstrapService.data.landing;

        const heroData = homeData.sections.find(s => s.name === "intro");
        const approvalsData = homeData.sections.find(s => s.name === "approvals");
        const coursesData = homeData.sections.find(s => s.name === "catalog");
        const appStoreData = homeData.sections.find(s => s.name === "appStore");
        const spacer1Data = homeData.sections.find(s => s.name === "spacer1");
        const howItWorksData = homeData.sections.find(s => s.name === "works");
        const tryForFreeData = homeData.sections.find(s => s.name === "tryForFree");
        const spacer2Data = homeData.sections.find(s => s.name === "spacer2");
        const whyUsData = homeData.sections.find(s => s.name === "features");
        const plansData = homeData.sections.find(s => s.name === "plans");

        const title = bootstrapService.data.site.siteEmailFromName;

        return (
            <>
                <ReactTitle title={title} />

                <Navbar ref={navbar => this.navbar = navbar} clickTry={this.clickTryForFree} />
                <div className="a4-view">
                    <div className="a4-layout a4-home">
                        <div className="a4-home-content">
                            {
                                heroData && <Exposition data={heroData} type="hero" highlighted={true} />
                            }
                            {
                                approvalsData && <Exposition data={approvalsData} type="approvals" />
                            }
                            {
                                coursesData && coursesData.courses.length > 1 &&
                                <List data={coursesData} type="courses" highlighted={true}>
                                    {
                                        coursesData.courses.map((course, index) => {
                                            return <CourseItem data={course} key={index} />
                                        })
                                    }
                                </List>
                            }
                            {
                                coursesData && coursesData.courses.length === 1 &&
                                <SingleCourse  data={coursesData} course={coursesData.courses[0]}/>
                            }
                            {
                                appStoreData && <AppStore data={appStoreData} />
                            }
                            {
                                spacer1Data && <Spacer data={spacer1Data} type="one" backgroundColor="primary_light" />
                            }
                            {
                                howItWorksData &&
                                <List data={howItWorksData} type="howItWorks">
                                    {
                                        howItWorksData.works.map((work, index) => {
                                            return <HowItWorksItem data={work} key={index} />
                                        })
                                    }
                                </List>
                            }
                            {
                                tryForFreeData && <TryForFree data={tryForFreeData} ref={section => this.tryForFreeSection = section} />
                            }
                            {
                                spacer2Data && <Spacer data={spacer2Data} type="two" backgroundColor="primary_light" />
                            }
                            {
                                whyUsData &&
                                <List data={whyUsData} type="whyUs">
                                    {
                                        whyUsData.features.map((feature, index) => {
                                            return <WhyUsItem data={feature} key={index} />
                                        })
                                    }
                                </List>
                            }
                            {
                                plansData && <Plans data={plansData} openGroupPlanModal={this.toggleGroupPlanModal} />
                            }
                            <Reviews />
                            <Subscribe />
                            <Footer />
                        </div>
                    </div>
                </div>
                <GroupsModal ref={modal => this.groupPlanModal = modal} goToCreateGroup={this.goToCreateGroup} goToJoinGroup={this.goToJoinGroup}/>
            </>
        )
    }
}

export default Home;