import React from 'react'
import './GroupsModal.scss'
import $ from 'jquery'
import 'select2'

import BubbleButton from '../../shared/BubbleButton/BubbleButton'
import { ReactComponent as BackButton } from '../../../assets/chevron-left.svg'
import { ReactComponent as Cancel } from '../../../assets/cancel.svg'
import { ReactComponent as Error } from '../../../assets/error.svg'

import formService from '../../../services/FormService'
import memberService from '../../../services/MemberService'
import recaptchaService from '../../../services/RecaptchaService'

class GroupsModal extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // State
        this.scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;

        // Refs
        this.modal = null;
        this.screen1 = null;
        this.screen2 = null;
        this.screen3 = null;

        // This Binding
        this.toggleModal = this.toggleModal.bind(this);
        this.showCreateGroup = this.showCreateGroup.bind(this);
        this.showJoinGroup = this.showJoinGroup.bind(this);
        this.clickCreateGroup = this.clickCreateGroup.bind(this);
        this.clickJoinGroup = this.clickJoinGroup.bind(this);
        this.clickBack = this.clickBack.bind(this);
    }

    // Lifecycle
    componentDidMount() {
        this.setupSelects();
    }
    
    componentDidUpdate() {
        this.setupSelects();
    }

    // Helpers
    setupSelects() {
        $('.a4-select select').select2({
            minimumResultsForSearch: -1,
        });

        $('.a4-select select').on("change", (e, shouldIgnore) => {
            e.stopPropagation();
            if (!shouldIgnore) {
                const { plan } = e.currentTarget.dataset;
                console.log("SELECTED " + plan);
            }
        });
    }
    
    // Event Handlers
    toggleModal(e) {
        if (e) e.stopPropagation(); // Prevent toggleModal from firing twice on Cancel
        
        if (!this.modal.classList.contains("-opened")) {
            this.modal.classList.add("-opened");
            document.body.classList.add("overflow-hidden");
            document.body.style.paddingRight = `${this.scrollBarWidth}px`;
            this.screen1.style.display = "block";
            this.screen2.style.display = "none";
            this.screen3.style.display = "none";
        }
        else {
            this.modal.classList.remove("-opened");
            document.body.classList.remove("overflow-hidden");
            document.body.style.paddingRight = 0;
            this.screen2.style.display = "none";
            this.screen3.style.display = "none";
        }
    }

    showCreateGroup(e) {
        e.stopPropagation();
        this.screen1.style.display = "none";
        this.screen2.style.display = "block";
    }

    showJoinGroup(e) {
        e.stopPropagation();
        
        formService.clearAllInputs(this.screen3)
        formService.hideAllErrors(this.screen3)

        this.screen1.style.display = "none";
        this.screen3.style.display = "block";
    }

    clickCreateGroup(e) {
        e.stopPropagation();
        this.toggleModal();
    }

    clickJoinGroup(e) {
        e.stopPropagation();
        
        const isFormValid = formService.validate(this.screen3);
        if(!isFormValid) return;

        const groupCode = this.screen3.querySelector("input").value;

        formService.showLoadingButton(this.screen3);

        let joinGroupRequest = { groupCode };

        recaptchaService.getToken("joinGroup")
        .then(token => {
            joinGroupRequest.recaptchaToken = token;

            memberService.validateJoinGroup(joinGroupRequest)
            .then(json => {    
                if (json.succeeded) {
                    this.props.goToJoinGroup(groupCode);
                }
                else {
                    formService.hideAllErrors(this.screen3);
                    formService.hideLoadingButton(this.screen3);
                    if (json.errors[0] === "Cannot get group") {
                        formService.showInputError(this.screen3, "groupCode", "Invalid group code");
                    }
                    else {
                        formService.showInputError(this.screen3, "groupCode", json.errors[0]);
                    }  
                }
            });
        })
    }

    clickBack(e) {
        e.stopPropagation();
        this.screen3.style.display = "none";
        this.screen2.style.display = "none";
        this.screen1.style.display = "block";
    }

    stopPropagation(e) {
        e.stopPropagation();
    }

    // Component
    render() {
        return (
            <div className="a4-modal" ref={div => this.modal = div} onClick={this.toggleModal}>
                <div className="a4-modal-content" onClick={this.stopPropagation}>
                    <svg className="a4-svgsprite -cancel text-secondary" onClick={this.toggleModal}>
                        <Cancel />
                    </svg>
                    <div className="a4-find-plan-modal">
                        <div className="screen screen-one" ref={div => this.screen1 = div}>
                            <header className="a4-find-plan-modal-title">What would you like to do?</header>
                            <div className="a4-find-plan-modal-btn-group">
                                <BubbleButton className="-primary -new-group" onClick={this.props.goToCreateGroup}>
                                    Create New Group
                                </BubbleButton>
                                <div className="button a4-btn -inverted -join-group" type="button" onClick={this.showJoinGroup}>
                                    Join Existing Group
                                </div>
                            </div>
                        </div>
                        <div className="screen screen-two" ref={div => this.screen2 = div}>
                            <svg className="a4-svgsprite -back text-secondary" onClick={this.clickBack}>
                                <BackButton />
                            </svg>
                            <header className="a4-find-plan-modal-title">Create New Group</header>
                            <div className="a4-input">
                                <input name="users" placeholder="Enter the number of users" required onClick={this.stopPropagation}/>
                                <label htmlFor="users">Number of users</label>
                                <span className="a4-input-error"></span>
                                <svg className="a4-svgsprite -error text-secondary">
                                    <Error />
                                </svg>
                            </div>
                            <div className="a4-select">
                                <select defaultValue={"selected"} onClick={this.stopPropagation}>
                                    <option value="selected" disabled>Select the group plan</option>
                                    <option plan="plan1">Plan 1</option>
                                    <option plan="plan2">Plan 2</option>
                                    <option plan="plan3">Plan 3</option>
                                </select>
                            </div>
                            <div className="a4-find-plan-modal-btn-group">
                                <BubbleButton className="-primary" onClick={this.clickCreateGroup}>
                                    Create
                                </BubbleButton>
                            </div>
                        </div>
                        <div className="screen screen-three" ref={div => this.screen3 = div}>
                            <svg className="a4-svgsprite -back text-secondary" onClick={this.clickBack}>
                                <BackButton />
                            </svg>
                            <header className="a4-find-plan-modal-title">Please enter your group code</header>
                            <div className="a4-input">
                                <input name="groupCode" placeholder="Group Code" required onClick={this.stopPropagation} />
                                <label htmlFor="groupCode">Group Code</label>
                                <span className="a4-input-error">Enter a valid code</span>
                                <svg className="a4-svgsprite -error text-secondary">
                                    <Error />
                                </svg>
                            </div>
                            <div className="a4-find-plan-modal-btn-group">
                                <BubbleButton className="a4-form-submit -primary" onClick={this.clickJoinGroup}>
                                    Join
                                </BubbleButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default GroupsModal;
