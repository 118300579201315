import React from 'react'
import { Link } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'

import courseImage from '../../../../assets/course-plug.svg'

import bootstrapService from '../../../../services/BootstrapService'

class CourseItem extends React.Component {
    // Component
    render() {
        const { courseId, title, description, imageUrl, credits } = this.props.data;

        const length = imageUrl ? 150 : 250;
        const shortDescription = description ? description.substring(0, length).split(" ").slice(0, -1).join(" ") + "..." : null;

        return (
            <li className="a4-list-items-item">
                <Link to={`/course/${courseId}`}>
                    {
                        imageUrl &&
                        <div className="a4-list-items-item-image" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-duration="700">
                            <img src={imageUrl ? imageUrl : courseImage} alt="" />
                        </div>
                    }
                    <div className="a4-list-items-item-info">
                        <h3 className="a4-list-items-item-title" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-duration="1300">{title}</h3>
                        <div className="a4-list-items-item-details" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-duration="1400">
                            <div className="a4-list-items-item-details-label">{bootstrapService.getCreditsTerm(2)}:</div>
                            <span>{credits.toFixed(1)}</span>
                        </div>
                        <p className="a4-list-items-item-description -courses" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-duration="1400">{shortDescription}</p>
                    </div>
                </Link>
            </li>
        )
    }
}

export default CourseItem;