import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import AOS from 'aos'
import 'aos/dist/aos.css'
import 'select2/dist/css/select2.min.css'
import 'owl.carousel/dist/assets/owl.carousel.min.css'
import './App.scss' // must come after other css files

import Spinner from './components/shared/Spinner/Spinner'
import Home from './components/Home/Home'
import Approvals from './components/Approvals/Approvals'
import Courses from './components/Courses/Courses'
import CourseDetails from './components/CourseDetails/CourseDetails'
import Pricing from './components/Pricing/Pricing'
import Buy from './components/Buy/Buy'
import Payment from './components/Payment/Payment'
import Requirements from './components/Requirements/Requirements'
import Login from './components/Login/Login'
import Register from './components/Register/Register'
import ForgotPassword from './components/ForgotPassword/ForgotPassword'
import ResetPassword from './components/ResetPassword/ResetPassword'
import Help from './components/Help/Help'
import Faq from './components/Faq/Faq'
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy'
import Terms from './components/Terms/Terms'
import AffiliatedSites from './components/AffiliatedSites/AffiliatedSites'
import ConfirmEmail from './components/ConfirmEmail/ConfirmEmail'
import CompleteProfile from './components/CompleteProfile/CompleteProfile'
import CreateGroup from './components/CreateGroup/CreateGroup'
import RefundPolicy from './components/RefundPolicy/RefundPolicy'
import ComplaintPolicy from './components/ComplaintPolicy/ComplaintPolicy'
import CustomPage from './components/CustomPage/CustomPage'

import Error from './components/shared/Error/Error'
import AppError from './components/shared/AppError/AppError'

import bootstrapService from './services/BootstrapService'

class App extends React.Component {
  constructor(props) {
    super(props);

    // State
    this.state = {
      dataLoading: true,
      dataError: false,
    }
  }

  // Lifecycle
  componentDidMount() {
    this.setupAOS();
    this.getBoostrap();
  }

  // Helpers
  setupAOS() {
      AOS.init({
        once: true,
        duration: 1200,
        delay: 190,
        easing: "outexpo",
        maxDistance: 75,
        anchorPlacement: "top-bottom",
      });
  }

  // Data
  getBoostrap() {
    bootstrapService.getBootstrap()
    .then(() => {
      this.setState({ dataLoading: false });
    })
    .catch((e) => {
      console.log(`Get Bootstrap Error - ${e}`);
      this.setState({ dataError: true });
      bootstrapService.reportError({ "source": "getBootstrap", "error": e.message });
    })
  }

  // Component
  render() {
    const loading = this.state.dataLoading;
    const error = this.state.dataError;

    return (
      <>
        {
          loading && !error &&
          <div className="spinner-container">
              <Spinner />
          </div>
        }

        {
          !loading && !error &&
          <Router>
            <Switch>
              {/* Routes */}
              <Route path="/" exact component={Home} />
              <Route path="/approvals" exact component={Approvals} />
              <Route path="/courses" exact component={Courses} />
              <Route path="/course/:id" exact component={CourseDetails} />
              <Route path="/pricing/:plan" exact component={Pricing} />
              <Route path="/requirements/:state" exact component={Requirements} />
              <Route path="/buy" exact component={Buy} />
              <Route path="/payment" exact component={Payment} />
              <Route path="/login" exact component={Login} />
              <Route path="/register" exact component={Register} />
              <Route path="/register/:group" exact component={Register} />
              <Route path="/forgot-password" exact component={ForgotPassword} />
              <Route path="/reset-password/:token" exact component={ResetPassword} />
              <Route path="/help-and-support" exact component={Help} />
              <Route path="/faq" exact component={Faq} />
              <Route path="/affiliated-sites" exact component={AffiliatedSites} />
              <Route path="/privacy-policy" exact component={PrivacyPolicy} />
              <Route path="/terms-and-conditions" exact component={Terms} />
              <Route path="/confirm-email/:token" exact component={ConfirmEmail} />
              <Route path="/complete-profile/:token" exact component={CompleteProfile} />
              <Route path="/create-group/" exact component={CreateGroup} />
              <Route path="/refund-policy" exact component={RefundPolicy} />
              <Route path="/complaint-resolution-policy" exact component={ComplaintPolicy} />

              {/* Redirects */}
              <Route exact path="/pricing"><Redirect to={`/pricing/${bootstrapService.defaultPlanType}`}></Redirect></Route>

              {/* generic page */}
              <Route path="/:name" exact component={CustomPage} />

              {/* Not Found */}
              <Route component={Error} />
            </Switch>
          </Router>
        }

        {/* Bootstrap Error */}
        {
          error &&
          <div className="error-container">
            <AppError />
          </div>
        }
      </>
    )
  }
}

export default App;
