import React from 'react'
import { Link } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'

import Navbar from '../shared/Navbar/Navbar'
import Footer from '../shared/Footer/Footer'
import BubbleButton from '../shared/BubbleButton/BubbleButton'
import { ReactComponent as Error } from '../../assets/error.svg'

import bootstrapService from '../../services/BootstrapService'
import formService from '../../services/FormService'
import memberService from '../../services/MemberService'
import recaptchaService from '../../services/RecaptchaService'

class CompleteProfile extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.form = null;

        // State
        this.state = {
            requireFirstName: true,
            requireLastName: true,
            requirePassword: true,
            email: null,
            firstName: null,
            middleName: null,
            lastName: null,
            errorBody: "Your account could not be verified.",
        }

        // This Binding
        this.submitForm = this.submitForm.bind(this);
    }

    // Lifecycle
    componentDidMount() {
        window.scrollTo(0, 0);
        this.setBootstrapCallback();
    }

    // Helpers
    setBootstrapCallback() {
        bootstrapService.setCallback(() => {
            return this.validateToken();
        })
    }

    validateToken() {
        const token = this.props.match.params.token;

        return memberService.validateCompleteProfileToken(token)
        .then(json => {
            if (json.succeeded) {
                if (json.result.status === 0) {
                    this.setState({ 
                        requireFirstName: json.result.requireFirstName,
                        requireLastName: json.result.requireLastName,
                        requirePassword: json.result.requirePassword,

                        email: json.result.email,
                        firstName: json.result.firstName,
                        middleName: json.result.middleName,
                        lastName: json.result.lastName,
                    });
                }
                else if (json.result.status === 1) {
                    this.setState({ errorBody: "Your account could not be verified.<br></br>Cannot find user." });
                }
                else if (json.result.status === 2) {
                    this.setState({ errorBody: "Your account could not be verified.<br></br>Cannot find member." });
                }
                else if (json.result.status === 3) {
                    this.setState({ errorBody: "Your account could not be verified.<br></br>Cannot update member." });
                }
                else if (json.result.status === 4) {
                    this.setState({ errorBody: "Your account could not be verified.<br></br>Token is invalid." }); 
                }
                else if (json.result.status === 5) {
                    this.setState({ errorBody: "Your account could not be verified.<br></br>Token is expired." });
                }
                else if (json.result.status === 6) {
                    this.setState({ errorBody: "Your account could not be verified.<br></br>Complete profile is not required." });
                }
            }
            else {
                this.setState({ errorBody: `Your account could not be verified.<br></br>${json.errors[0]}` });
            }
        })
    }

    // Event Handlers
    submitForm(e) {
        e.preventDefault();

        const isFormValid = formService.validate(this.form);

        const firstName = this.state.requireFirstName ? e.target.firstName.value : "";
        const middleName = e.target.middleName.value;
        const lastName = this.state.requireLastName ? e.target.lastName.value : "";
        const password = this.state.requirePassword ? e.target.password.value : "";
        const confirm = this.state.requirePassword ? e.target.confirm.value : ""; 

        if (this.state.requirePassword && (password !== confirm)) {
            formService.showInputError(this.form, "confirm", "This password does not match");
            formService.hideLoadingButton(this.form);
            return;
        }

        if(!isFormValid) return;

        formService.showLoadingButton(this.form);

        let completeProfileRequest = {firstName, middleName, lastName, password};
        completeProfileRequest.token = this.props.match.params.token;

        recaptchaService.getToken("completeProfile")
        .then(token => {
            completeProfileRequest.recaptchaToken = token;

            memberService.completeProfile(completeProfileRequest)
            .then(json => {    
                if (json.succeeded) {
                    memberService.goToMembers();
                }
                else {
                    formService.hideAllErrors(this.form);
                    formService.hideLoadingButton(this.form);
                    formService.showFormError(this.form, json.errors[0]);
                }
            });
        });
    }

    // Component
    render() {
        const tokenValidated = memberService.completeProfileValidated;

        const email = this.state.email;
        const firstName = this.state.firstName ? this.state.firstName : "";
        const middleName = this.state.middleName ? this.state.middleName : "";
        const lastName = this.state.lastName ? this.state.lastName : "";

        const requireFirstName = this.state.requireFirstName;
        const requireLastName = this.state.requireLastName;
        const requirePassword = this.state.requirePassword;

        const errorBody = this.state.errorBody;

        return (
            <>
                <Navbar activeLink="register" />
                <div className="a4-view">
                    <main className="a4-layout a4-auth -register">
                        {
                            tokenValidated &&
                            <div className="a4-auth-container -register">
                                <section className="a4-auth-item-container">
                                    <div className="a4-auth-item-shadow"></div>
                                    <div className="a4-auth-item-content" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                        <h1 className="a4-auth-item-title"><span className="text-primary">Complete</span> Profile</h1>
                                        <form className="a4-auth-form" ref={form => this.form = form} onSubmit={this.submitForm}>
                                            <div className="a4-input">
                                                <input name="firstName" required placeholder="First Name" defaultValue={firstName} disabled={!requireFirstName} />
                                                <label htmlFor="firstName">First Name</label>
                                                <span className="a4-input-error">Enter your first name</span>
                                                <svg className="a4-svgsprite -error text-secondary">
                                                    <Error />
                                                </svg>
                                            </div>
                                            <div className="a4-input">
                                                <input name="middleName" placeholder="Middle Name" defaultValue={middleName} />
                                                <label htmlFor="middleName">Middle Name (Optional)</label>
                                                <span className="a4-input-error"></span>
                                                <svg className="a4-svgsprite -error text-secondary">
                                                    <Error />
                                                </svg>
                                            </div>
                                            <div className="a4-input">
                                                <input name="lastName" required placeholder="Last Name" defaultValue={lastName} disabled={!requireLastName} />
                                                <label htmlFor="lastName">Last Name</label>
                                                <span className="a4-input-error">Enter your last name</span>
                                                <svg className="a4-svgsprite -error text-secondary">
                                                    <Error />
                                                </svg>
                                            </div>
                                            <div className="a4-input">
                                                <input name="email" required placeholder="E-mail address" value={email} disabled={true} />
                                                <label htmlFor="email">E-mail address</label>
                                                <span className="a4-input-error" ref={span => this.emailError = span}>Enter a valid e-mail address</span>
                                                <svg className="a4-svgsprite -error text-secondary">
                                                    <Error />
                                                </svg>
                                            </div>
                                            {
                                                requirePassword &&
                                                <>
                                                    <div className="a4-input">
                                                        <input name="password" placeholder="Password" required type="password" />
                                                        <label htmlFor="password">Password</label>
                                                        <span className="a4-input-error">Enter a valid password</span>
                                                        <svg className="a4-svgsprite -error text-secondary">
                                                            <Error />
                                                        </svg>
                                                    </div>
                                                    <div className="a4-input">
                                                        <input name="confirm" placeholder="Confirm Password" required type="password" />
                                                        <label htmlFor="password">Confirm Password</label>
                                                        <span className="a4-input-error" ref={span => this.confirmError = span}>Enter a valid password</span>
                                                        <svg className="a4-svgsprite -error text-secondary">
                                                            <Error />
                                                        </svg>
                                                    </div>
                                                </>
                                            }
                                            <p className="a4-form-error"></p>
                                            <section className="a4-auth-form-control -join">
                                                <BubbleButton className="a4-form-submit -primary" type="submit" formNoValidate>
                                                    <b>Create Account</b>
                                                </BubbleButton>
                                            </section>
                                        </form>
                                    </div>
                                </section>
                                <aside className="a4-auth-additional" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-delay='100'>
                                    <span>
                                        Already have an account? &nbsp;
                                        <Link to="/login" className="text-primary">Login.</Link>
                                    </span>
                                </aside>
                            </div>
                        }
                        {
                            !tokenValidated &&
                            <div className="a4-auth-container">
                                <section className="a4-auth-item-container">
                                    <div className="a4-auth-item-shadow"></div>
                                    <div className="a4-auth-item-content confirm-email" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                        <h1 className="a4-auth-item-title">Complete Profile <span className="text-primary">Error</span></h1>
                                        <p dangerouslySetInnerHTML={{ __html: errorBody}}></p>
                                        <form className="a4-auth-form" onSubmit={this.submitForm}>
                                            <section className="a4-auth-form-control">
                                                <Link to="/help-and-support">
                                                    <BubbleButton className="-primary" type="submit" formNoValidate>
                                                        <b>Get Help</b>
                                                    </BubbleButton>
                                                </Link>
                                            </section>
                                        </form>
                                    </div>
                                </section>
                                <aside className="a4-auth-additional" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-delay='100'>
                                    <span>
                                        Don’t have an Account? &nbsp;
                                        <Link to="/register" className="text-primary">Register now!</Link>
                                    </span>
                                </aside>
                            </div>
                        }
                        <Footer />
                    </main>
                </div>
            </>
        )
    }
}

export default CompleteProfile;