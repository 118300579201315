import React from 'react'
import './CourseDetails.scss'
import { isMobileOnly } from 'react-device-detect'

import Navbar from '../shared/Navbar/Navbar'
import Reviews from '../shared/Reviews/Reviews'
import Subscribe from '../shared/Subscribe/Subscribe'
import Footer from '../shared/Footer/Footer'
import BubbleButton from '../shared/BubbleButton/BubbleButton'
import Calendar from '../shared/Calendar/Calendar'
import placeholderImage from '../../assets/course-plug.svg'
import { ReactComponent as Avatar } from '../../assets/avatar-plug.svg'
import { ReactComponent as CheckMark } from '../../assets/check-mark.svg'

import bootstrapService from '../../services/BootstrapService'
import paymentService from '../../services/PaymentService'

class CourseDetails extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.courseDescription = null;
        this.courseDescriptionButton = null;
        this.authorBios = [];
        this.authorBiosButtons = [];
        this.courseObjectives = null;
        this.courseObjectivesButton = null;

        // State
        this.state = {
            schedule: null
        }

        // This Binding
        this.clickReadMoreDescription = this.clickReadMoreDescription.bind(this);
        this.clickReadMoreBio = this.clickReadMoreBio.bind(this);
        this.clickReadMoreObjectives = this.clickReadMoreObjectives.bind(this);
        this.goToBuy = this.goToBuy.bind(this);
        this.selectSchedule = this.selectSchedule.bind(this);
        this.buySchedule = this.buySchedule.bind(this);
    }

    // Lifecycle
    componentDidMount() {
        window.scrollTo(0, 0);
        this.setupReadMoreButtons();
    }

    // Helpers
    setupReadMoreButtons() {
        // Timeout hack ensures function runs after Render (so that REFs are set)
        setTimeout(() => {
            if (this.courseDescription) {
                const style = getComputedStyle(this.courseDescription);
                // console.log("description = " + parseInt(style.height))
                if (parseInt(style.height) < 200) {
                    this.courseDescriptionButton.style.display = "none";
                }
                // else {
                //     this.courseDescription.classList.add("-blur");
                // }
            }

            if (this.authorBios.length > 0) {

                this.authorBios.forEach((bio, index) => {

                    const style = getComputedStyle(bio);
                    // console.log("bio = " + parseInt(style.height))
                    if (parseInt(style.height) < 100) {
                        this.authorBiosButtons[index].style.display = "none";
                    }
                    // else {
                    //     this.authorBios[index].classList.add("-blur");
                    // }
                })
            }

            if (this.courseObjectives) {
                const style = getComputedStyle(this.courseObjectives);
                // console.log("objectives = " + parseInt(style.height))
                if (parseInt(style.height) < 300) {
                    this.courseObjectivesButton.style.display = "none";
                }
                // else {
                //     this.courseObjectives.classList.add("-blur");
                // }
            }
        }, 0);
    }

    // Event Hanlders
    clickReadMoreDescription(e) {
        e.preventDefault();
        this.courseDescription.classList.add("-show");
        this.courseDescriptionButton.style.display = "none";
    }

    clickReadMoreBio(e) {
        e.preventDefault();
        const index = e.currentTarget.getAttribute("index")
        this.authorBios[index].classList.add("-show");
        this.authorBiosButtons[index].style.display = "none";
    }

    clickReadMoreObjectives(e) {
        e.preventDefault();
        this.courseObjectives.classList.add("-show");
        this.courseObjectivesButton.style.display = "none";
    }

    goToBuy(item) {
        if (item.price) {
            paymentService.reset();
            paymentService.item = { ...item, quantity: 1 };

            if (this.state.schedule) {
                paymentService.schedule = this.state.schedule;
            }

            this.props.history.push("/buy");
        }
        else {
            this.props.history.push({ pathname: "/", state: { tryForFree: true } });
        }
    }

    selectSchedule(schedule) {
        this.setState({ schedule: schedule });
    }

    buySchedule(item) {
        this.goToBuy(item)
    }

    // Data
    getBundles(courseId) {
        const bundles = bootstrapService.data.courseBundleTypes;
        if (bundles) {
            let bundlesWithCourse = [];
            bundles.forEach((bundle, index) => {
                if (bundle.courseDigests && bundle.courseDigests.filter(c => c.id === courseId).length > 0) {
                    bundlesWithCourse.push(bundle);
                }
            })
            return bundlesWithCourse.length > 0 ? bundlesWithCourse : null;
        }
    }

    // Component
    render() {
        const courses = bootstrapService.data.courseCatalog.courses;
        const course = courses.find(c => c.id === this.props.match.params.id);

        if (!course) {
            this.props.history.push("/courses");
        }

        const categoryTerm = bootstrapService.getCategoryTerm();
        const releaseDate = course ? new Date(course.releaseDate).toDateString().split(' ').slice(1).join(' ') : null;

        const memberships = bootstrapService.data.passTypes;
        const bundles = this.getBundles(course.id);

        const schedule = this.state.schedule ? new Date(this.state.schedule.startDate).toLocaleDateString("en-US", { weekday: 'long', month: 'long', day: 'numeric' }) : null;

        const showReleaseDate = bootstrapService.getShowReleaseDate();

        return (
            <>
                <Navbar activeLink={bootstrapService.singleCourseId ? "courses" : ""} />
                {
                    course &&
                    <div className="a4-view">
                        <div className="a4-layout a4-course-details">
                            <section className="a4-course-details-main">
                                <div className="a4-course-details-main-container">
                                    <div className="a4-course-details-main-content">
                                        {
                                            course.imageUrl &&
                                            <div className="a4-course-details-main-cover" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                                <img src={course.imageUrl ? course.imageUrl : placeholderImage} alt="" />
                                            </div>
                                        }
                                        <div className="a4-course-details-main-title" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                            <h1>{course.title}</h1>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="a4-course-details-info">
                                <div className="a4-course-details-info-container">
                                    <div className="a4-course-details-info-content">
                                        <div className="a4-course-details-info-side" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                            <ul className="a4-course-details-list">
                                                {/* <li className="a4-course-details-list-item">
                                                    <div className="a4-course-details-list-item-label">Course No:</div>
                                                    <div className="a4-course-details-list-item-text">{course.courseNo}</div>
                                                </li> */}
                                                {
                                                    course.subjectArea &&
                                                    <li className="a4-course-details-list-item">
                                                        <div className="a4-course-details-list-item-label">{categoryTerm}:</div>
                                                        <div className="a4-course-details-list-item-text">{course.subjectArea}</div>
                                                    </li>
                                                }
                                                {
                                                    course.audience &&
                                                    <li className="a4-course-details-list-item">
                                                        <div className="a4-course-details-list-item-label">Designed For:</div>
                                                        <div className="a4-course-details-list-item-text">{course.audience}</div>
                                                    </li>
                                                }
                                                {
                                                    course.deliveryMethod &&
                                                    <li className="a4-course-details-list-item">
                                                        <div className="a4-course-details-list-item-label">Delivery Method:</div>
                                                        <div className="a4-course-details-list-item-text">{course.deliveryMethod}</div>
                                                    </li>
                                                }
                                                {
                                                    course.schedules && course.schedules[0] &&
                                                    <li className="a4-course-details-list-item">
                                                        <div className="a4-course-details-list-item-label">Next Session:</div>
                                                        <div className="a4-course-details-list-item-text">{new Date(course.schedules[0].startDate).toLocaleDateString("en-US", {weekday: 'long', month: 'short', day: 'numeric'})}</div>
                                                    </li>
                                                }
                                                {
                                                    course.knowledgeLevel &&
                                                    <li className="a4-course-details-list-item">
                                                        <div className="a4-course-details-list-item-label">Knowledge Level:</div>
                                                        <div className="a4-course-details-list-item-text">{course.knowledgeLevel}</div>
                                                    </li>
                                                }
                                                {
                                                    course.advancePreparation &&
                                                    <li className="a4-course-details-list-item">
                                                        <div className="a4-course-details-list-item-label">Advance Preparation:</div>
                                                        <div className="a4-course-details-list-item-text">{course.advancePreparation}</div>
                                                    </li>
                                                }
                                                {
                                                    course.prerequisites &&
                                                    <li className="a4-course-details-list-item">
                                                        <div className="a4-course-details-list-item-label">Prerequisites:</div>
                                                        <div className="a4-course-details-list-item-text">{course.prerequisites}</div>
                                                    </li>
                                                }
                                                {
                                                    course.commercialSupport &&
                                                    <li className="a4-course-details-list-item">
                                                        <div className="a4-course-details-list-item-label">Commercial Support:</div>
                                                        <div className="a4-course-details-list-item-text">{course.commercialSupport}</div>
                                                    </li>
                                                }
                                                {
                                                    course.jointProvidership &&
                                                    <li className="a4-course-details-list-item">
                                                        <div className="a4-course-details-list-item-label">Joint Providership:</div>
                                                        <div className="a4-course-details-list-item-text">{course.jointProvidership}</div>
                                                    </li>
                                                }
                                                {
                                                    showReleaseDate && releaseDate &&
                                                    <li className="a4-course-details-list-item">
                                                        <div className="a4-course-details-list-item-label">Publish Date:</div>
                                                        <div className="a4-course-details-list-item-text">{releaseDate}</div>
                                                    </li>
                                                }
                                                <li className="a4-course-details-list-item">
                                                    <div className="a4-course-details-list-item-label">{bootstrapService.getCreditsTerm(2)}:</div>
                                                    <div className="a4-course-details-list-item-text">{course.credits.toFixed(1)}</div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="a4-course-details-info-body">
                                            <div className="a4-course-details-about">
                                                <div className="a4-course-details-about-title" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                                    <h2>About Course</h2>
                                                </div>
                                                <div data-aos={isMobileOnly ? undefined : "fade-up"}>
                                                    <div className="a4-course-details-about-description" ref={div => this.courseDescription = div} dangerouslySetInnerHTML={{ __html: course.description }}>
                                                    </div>
                                                    <div className="a4-course-details-about-control">
                                                        <button ref={button => this.courseDescriptionButton = button} onClick={this.clickReadMoreDescription}>Read More</button>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                course.authors && course.authors.map((author, index) => {
                                                    return <div className="a4-course-details-from-author" key={index}>
                                                        <div className="a4-course-details-from-author-info" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                                            <div className="a4-course-details-from-author-avatar">
                                                                {
                                                                    author.imageUrl &&
                                                                    <img src={author.imageUrl} alt="" />
                                                                }
                                                                {
                                                                    !author.imageUrl &&
                                                                    <Avatar />
                                                                }
                                                            </div>
                                                            <div className="a4-course-details-from-author-details">
                                                                <div className="a4-course-details-from-author-name">Author</div>
                                                                <div className="a4-course-details-from-author-from">{author.name} &nbsp; &nbsp; {author.designations}</div>
                                                            </div>
                                                        </div>
                                                        {
                                                            author.bio &&
                                                            <div data-aos={isMobileOnly ? undefined : "fade-up"}>
                                                                <div className="a4-course-details-from-author-comment" ref={div => this.authorBios[index] = div} dangerouslySetInnerHTML={{ __html: author.bio }}>
                                                                </div>
                                                                <div className="a4-course-details-about-control">
                                                                    <button index={index} ref={button => this.authorBiosButtons[index] = button} onClick={this.clickReadMoreBio}>Read More</button>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </section>
                            {
                                course.sections &&
                                <section className="a4-course-details-objectives">
                                    <div className="a4-course-details-objectives-container">
                                        <div className="a4-course-details-objectives-content">
                                            <div className="a4-course-details-objectives-side" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                                <h3>Learning Objectives:</h3>
                                            </div>
                                            <div className="a4-course-details-objectives-body">
                                                <div className="a4-course-details-objectives-sections" ref={div => this.courseObjectives = div}>
                                                    {
                                                        course.sections.map((section, sectionIndex) => {
                                                            return <div className="a4-course-details-objectives-section" key={sectionIndex}>
                                                                <div className="a4-course-details-objectives-section-header" data-aos={isMobileOnly ? undefined : "fade-up"}>{section.title}</div>
                                                                <ul className="a4-course-details-objectives-list">
                                                                    {
                                                                        section.objectives.map((objective, objectiveIndex) => {
                                                                            return <li className="a4-course-details-objectives-list-item" key={objectiveIndex} data-aos={isMobileOnly ? undefined : "fade-up"}>
                                                                                <div className="a4-course-details-objectives-list-item-number"></div>
                                                                                <div className="a4-course-details-objectives-list-item-info">
                                                                                    <p className="a4-course-details-objectives-list-item-description">{objective}</p>
                                                                                </div>
                                                                            </li>
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                                <div className="a4-course-details-objectives-control" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                                    <button ref={button => this.courseObjectivesButton = button} onClick={this.clickReadMoreObjectives}>Read More</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            }
                            {
                                course.schedules &&
                                <section className="a4-course-details-schedules">
                                    <div className="a4-course-details-schedules-container">
                                        <div className="a4-course-details-schedules-content">
                                            <div className="a4-course-details-schedules-side" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                                <h3>Dates & Times:</h3>
                                            </div>
                                            <div className="a4-course-details-schedules-body" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                                <Calendar schedules={course.schedules} selectSchedule={this.selectSchedule} buySchedule={this.buySchedule} item={course.item} /> 
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            }
                            <section className="a4-course-details-pricing">
                                {
                                    course.item &&
                                    <div className="a4-course-details-pricing-course">
                                        <div className="a4-course-plan-container" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-duration='1400' data-aos-delay="0" data-aos-anchor-placement="top-bottom">
                                            <div className="a4-course-plan-item">
                                                <div className="a4-course-plan-section">
                                                    <h3 className="a4-course-plan-title">{course.item.price ? 'Buy' : 'Take'} Course</h3>
                                                    <p className="a4-course-plan-description">{course.title}</p>
                                                    <p className="a4-course-plan-schedule">{schedule}</p>
                                                    <div className="a4-course-plan-section-subsection">
                                                        <BubbleButton onClick={() => this.goToBuy(course.item)} className="a4-course-plan-buy-now -primary">{course.item.price ? 'Buy' : 'Take'} Now</BubbleButton>
                                                    </div>
                                                </div>
                                                <div className="a4-course-plan-section">
                                                    <div className="a4-course-plan-price">
                                                        <b className="a4-course-plan-price-digits">
                                                            <span className="a4-course-plan-price-int">${String(course.item.price).split('.')[0]}</span>
                                                            <span className="a4-course-plan-price-float">.{String(course.item.price).split('.')[1] ? String(course.item.price).split('.')[1] : '00'}</span>
                                                        </b>
                                                    </div>
                                                    <div className={`a4-course-plan-controls ${schedule ? "-bottomMargin" : ""}`}>
                                                        <BubbleButton onClick={() => this.goToBuy(course.item)} className="a4-course-plan-buy-now -primary">{course.item.price ? 'Buy' : 'Take'} Now</BubbleButton>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    memberships &&
                                    <div className="a4-course-details-pricing-container">
                                        <div className="a4-course-details-pricing-content">
                                            <h3 className="a4-course-details-pricing-title" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-duration='1400' data-aos-delay="0" data-aos-anchor-placement="top-bottom">Memberships</h3>
                                            <ul className="a4-pricing-list">
                                                {
                                                    memberships.map((membership, index) => {
                                                        return <div className="a4-pricing-plan-container" key={index} data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-duration='1400' data-aos-delay="0" data-aos-anchor-placement="top-bottom">
                                                            <div className="a4-pricing-plan-item">
                                                                <div className="a4-pricing-plan-section">
                                                                    <h3 className="a4-pricing-plan-title">{membership.item.title}</h3>
                                                                    <p className="a4-pricing-plan-description">{membership.item.descriptionText}</p>
                                                                    <div className="a4-pricing-plan-section-subsection">
                                                                        <BubbleButton onClick={() => this.goToBuy(membership.item)} className="a4-pricing-plan-buy-now -primary -inverted">Buy Now</BubbleButton>
                                                                    </div>
                                                                </div>
                                                                <div className="a4-pricing-plan-section">
                                                                    <div className="a4-pricing-plan-price">
                                                                        <b className="a4-pricing-plan-price-digits">
                                                                            <span className="a4-pricing-plan-price-int">${String(membership.item.price).split('.')[0]}</span>
                                                                            <span className="a4-pricing-plan-price-float">.{String(membership.item.price).split('.')[1] ? String(membership.item.price).split('.')[1] : '00'}</span>
                                                                        </b>
                                                                    </div>
                                                                    <div className="a4-pricing-plan-controls">
                                                                        <BubbleButton onClick={() => this.goToBuy(membership.item)} className="a4-pricing-plan-buy-now -primary -inverted">Buy Now</BubbleButton>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                }
                                {
                                    bundles &&
                                    <div className="a4-course-details-pricing-container">
                                        <div className="a4-course-details-pricing-content">
                                            <h3 className="a4-course-details-pricing-title" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-duration='1400' data-aos-delay="0" data-aos-anchor-placement="top-bottom">Course Bundles</h3>
                                            <ul className="a4-pricing-list">
                                                {
                                                    bundles.map((bundle, index) => {
                                                        return <li className="a4-pricing-plan-container -course-bundle" key={index} data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-duration='1400' data-aos-delay="0" data-aos-anchor-placement="top-bottom">
                                                            <div className="a4-pricing-plan-item">
                                                                <div className="a4-pricing-plan-section">
                                                                    <h3 className="a4-pricing-plan-title">{bundle.item.title}</h3>
                                                                    <p className="a4-pricing-plan-description">{bundle.item.descriptionText}</p>
                                                                    <div className="a4-pricing-plan-section-subsection">
                                                                        <BubbleButton onClick={() => this.goToBuy(bundle.item)} className="a4-pricing-plan-buy-now -primary -inverted">Buy Now</BubbleButton>
                                                                    </div>
                                                                    <ul className="a4-pricing-plan-advantages -course-bundle">
                                                                        {
                                                                            bundle.courseDigests.map((c, cIndex) => {
                                                                                return <li className="a4-pricing-plan-advantages-item -course-bundle" key={cIndex}>
                                                                                    {
                                                                                        c.id === course.id &&
                                                                                        <>
                                                                                            <svg className="a4-svgsprite">
                                                                                                <CheckMark stroke={bootstrapService.primaryColor} />
                                                                                            </svg>
                                                                                            <span className="a4-pricing-plan-advantages-item-text -primary">{c.title}</span>
                                                                                        </>
                                                                                    }
                                                                                    {
                                                                                        c.id !== course.id &&
                                                                                        <>
                                                                                            <svg className="a4-svgsprite">
                                                                                                <CheckMark stroke={bootstrapService.primaryColor} />
                                                                                            </svg>
                                                                                            <span className="a4-pricing-plan-advantages-item-text">{c.title}</span>
                                                                                        </>
                                                                                    }
                                                                                </li>
                                                                            })
                                                                        }
                                                                    </ul>
                                                                </div>
                                                                <div className="a4-pricing-plan-section -course-bundle">
                                                                    <div className="a4-pricing-plan-price">
                                                                        <b className="a4-pricing-plan-price-digits">
                                                                            <span className="a4-pricing-plan-price-int">${String(bundle.item.price).split('.')[0]}</span>
                                                                            <span className="a4-pricing-plan-price-float">.{String(bundle.item.price).split('.')[1] ? String(bundle.item.price).split('.')[1] : '00'}</span>
                                                                        </b>
                                                                    </div>
                                                                    <div className="a4-pricing-plan-controls -course-bundle">
                                                                        <BubbleButton onClick={() => this.goToBuy(bundle.item)} className="a4-pricing-plan-buy-now -primary -inverted">Buy Now</BubbleButton>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                }
                            </section>
                            <section className="a4-client-reviews">
                                <div className="a4-client-reviews-container">
                                    <div className="a4-client-reviews-content">
                                        <Reviews />
                                    </div>
                                </div>
                            </section>
                            <Subscribe />
                            <Footer />
                        </div>
                    </div>
                }
            </>
        )
    }
}

export default CourseDetails;