import React from 'react'
import './SingleCourse.scss'
import { Link } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'

import BubbleButton from '../../shared/BubbleButton/BubbleButton'

import bootstrapService from '../../../services/BootstrapService'

class SingleCourse extends React.Component {
    // Component
    render() {
        const { headers } = this.props.data;
        const { courseId, title, credits, releaseDate, description, imageUrl, schedules } = this.props.course;

        const length = imageUrl ? 200 : 300;
        const shortDescription = description.substring(0, length).split(" ").slice(0, -1).join(" ") + "...";

        const showReleaseDate = bootstrapService.getShowReleaseDate();

        return (
            <div className="a4-single-course -highlighted">
                <div className="a4-single-course-container">
                    <div className="a4-single-course-column">
                        <h1 className="a4-single-course-header" data-aos={isMobileOnly ? undefined : "fade-up"}>{headers[0]} <span className="text-primary">{headers[1]}</span></h1>
                        <div className="a4-single-course-content">
                            <div className="a4-single-course-details">
                                <h1 className="a4-single-course-title" data-aos={isMobileOnly ? undefined : "fade-up"}>{title}</h1>
                                <div className="a4-single-course-info" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-duration="1200">
                                    <div className="a4-single-course-info-item">
                                        <div className="a4-single-course-info-label">{bootstrapService.getCreditsTerm(credits)}:</div>
                                        <span>{credits.toFixed(1)}</span>
                                    </div>
                                    <div className="a4-single-course-info-item -right">
                                    {
                                        !schedules && showReleaseDate && releaseDate &&
                                        <>
                                            <div className="a4-single-course-info-label">Publish Date:</div>
                                            <span>{new Date(releaseDate).toDateString().split(' ').slice(1).join(' ')}</span>
                                        </>
                                    }
                                    {
                                        schedules &&
                                        <>
                                            <div className="a4-single-course-info-label">Next Session:</div>
                                            <span>{new Date(schedules[0].startDate).toLocaleDateString("en-US", {weekday: 'long', month: 'short', day: 'numeric'})}</span>
                                        </>
                                    }  
                                    </div>
                                </div>
                                <p className="a4-single-course-description" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-duration="1300">{shortDescription}</p>
                                <div className="a4-single-course-control" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-duration="1400">
                                    <Link to={`/course/${courseId}`}>
                                        <BubbleButton className="-primary">View Course</BubbleButton>
                                    </Link>
                                </div>
                            </div>
                            <div className="a4-single-course-illustration">
                                <img src={imageUrl} data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-easing="ease-out-quad" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SingleCourse;