import React from 'react'
import './Exposition.scss'
import { Link } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'

import BubbleButton from '../../shared/BubbleButton/BubbleButton'

class Exposition extends React.Component {
    // Component
    render() {
        const { headers, body, actionLabel, actionLink, imageUrl, imageBackgroundUrl } = this.props.data;
        const type = this.props.type;
        const highlighted = this.props.highlighted;

        return (
            <div className={"a4-exposition " + (type ? "-" + type + " " : "") + (highlighted ? "-highlighted" : "")}>
                <div className="a4-exposition-container">
                    <div className="a4-exposition-content">
                        <div className="a4-exposition-info">
                            <h1 className="a4-exposition-title" data-aos={isMobileOnly ? undefined : "fade-up"}>{headers[0]} <span className="text-primary">{headers[1]} </span>{headers[2]}</h1>
                            <p className="a4-exposition-description" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-duration="1300">{body}</p>
                            <div className="a4-exposition-control" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-duration="1400">
                                <Link to={actionLink ? actionLink : ""}>
                                    <BubbleButton className="-primary">{actionLabel} </BubbleButton>
                                </Link>
                            </div>
                        </div>
                        <div className="a4-exposition-illustration">
                            {
                                imageBackgroundUrl && <img src={imageBackgroundUrl} className="a4-exposition-illustration-background" alt="" />
                            }
                            <img src={imageUrl} data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-easing="ease-out-quad" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Exposition;