export function splitBody(body) {
    if (!body) return [null, null];

    var middle = Math.floor(body.length / 2);
    var before = body.lastIndexOf(' ', middle);
    var after = body.indexOf(' ', middle + 1);

    if (before === -1 || (after !== -1 && middle - before >= after - middle)) {
        middle = after;
    } else {
        middle = before;
    }

    var s1 = body.substr(0, middle);
    var s2 = body.substr(middle + 1);

    return [s1, s2];
}

export function getParameterByName(name, url = window.location.href) {
    name = name.replace(/\[\]]/g, '\\$&');                                      // old = /[\[\]]/g, '\\$&'
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}