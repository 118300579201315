import React from 'react'
import './Help.scss'
import { isMobileOnly } from 'react-device-detect'

import Navbar from '../shared/Navbar/Navbar'
import Subscribe from '../shared/Subscribe/Subscribe'
import Footer from '../shared/Footer/Footer'
import { ReactComponent as Letter } from '../../assets/letter.svg'
import { ReactComponent as Phone } from '../../assets/phone.svg'
import { ReactComponent as Location } from '../../assets/location.svg'
import { ReactComponent as World } from '../../assets/world.svg'
import { ReactComponent as Printer } from '../../assets/printer.svg'

import bootstrapService from '../../services/BootstrapService'

class Help extends React.Component {

    // Lifecycle
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    // Component
    render() {
        const helpData = bootstrapService.data.landing.pages.find(p => p.name === "help-support");

        const header = helpData ? helpData.header : null;
        const body = helpData ? helpData.bodyText : null;

        const siteData = bootstrapService.data.site;

        const email = siteData.siteEmail;
        const phone = siteData.sitePhone;
        const address1 = siteData.siteAddress.street;
        const address2 = siteData.siteAddress.city.concat(", ", siteData.siteAddress.state, " ", siteData.siteAddress.zip);
        const fullAddress = address1.concat(" ", address2);
        const url = siteData.name;
        const fax = siteData.siteFax;

        return (
            <>
                {
                    helpData && siteData &&
                    <>
                        <Navbar />
                        <div className="a4-view">
                            <div className="a4-layout a4-help-and-support">
                                <div className="a4- a4-help-and-support-container">
                                    <div className="a4- a4-help-and-support-page-title" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                        <h1 className="title">{header}</h1>
                                        <p className="description">{body}</p>
                                    </div>
                                    <div className="a4-help-and-support-contacts" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                        <ul className="a4-help-and-support-contacts-list">
                                            <li className="a4-help-and-support-contacts-list-item">
                                                <div className="a4-help-and-support-contacts-list-item-info">
                                                    <div className="a4-help-and-support-contacts-list-item-label">Email:</div>
                                                    <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
                                                        <div className="a4-help-and-support-contacts-list-item-value">{email}</div>
                                                    </a>
                                                </div>
                                                <div className="a4-help-and-support-contacts-list-item-icon">
                                                    <svg className="a4-svgsprite -letter text-secondary">
                                                        <Letter fill={bootstrapService.primaryColor} />
                                                    </svg>
                                                </div>
                                            </li>
                                            <li className="a4-help-and-support-contacts-list-item">
                                                <div className="a4-help-and-support-contacts-list-item-info">
                                                    <div className="a4-help-and-support-contacts-list-item-label">Phone:</div>
                                                    <a href={`tel:${phone}`}>
                                                        <div className="a4-help-and-support-contacts-list-item-value">{phone}</div>
                                                    </a>
                                                </div>
                                                <div className="a4-help-and-support-contacts-list-item-icon">
                                                    <svg className="a4-svgsprite -phone text-secondary">
                                                        <Phone stroke={bootstrapService.primaryColor} />
                                                    </svg>
                                                </div>
                                            </li>
                                            <li className="a4-help-and-support-contacts-list-item">
                                                <div className="a4-help-and-support-contacts-list-item-info">
                                                    <div className="a4-help-and-support-contacts-list-item-label">Mailing Address:</div>
                                                    <a href={`http://maps.google.com/?q=${fullAddress}`} target="_blank" rel="noreferrer">
                                                        <div className="a4-help-and-support-contacts-list-item-value">{address1}<br></br>{address2}</div>
                                                    </a>
                                                </div>
                                                <div className="a4-help-and-support-contacts-list-item-icon">
                                                    <svg className="a4-svgsprite -location text-secondary">
                                                        <Location stroke={bootstrapService.primaryColor} />
                                                    </svg>
                                                </div>
                                            </li>
                                            <li className="a4-help-and-support-contacts-list-item">
                                                <div className="a4-help-and-support-contacts-list-item-info">
                                                    <div className="a4-help-and-support-contacts-list-item-label">Website:</div>
                                                    <a href={`https://${url}`} target="_blank" rel="noreferrer">
                                                        <div className="a4-help-and-support-contacts-list-item-value">https://{url}</div>
                                                    </a>
                                                </div>
                                                <div className="a4-help-and-support-contacts-list-item-icon">
                                                    <svg className="a4-svgsprite -world text-secondary">
                                                        <World fill={bootstrapService.primaryColor} />
                                                    </svg>
                                                </div>
                                            </li>
                                            <li className="a4-help-and-support-contacts-list-item">
                                                <div className="a4-help-and-support-contacts-list-item-info">
                                                    <div className="a4-help-and-support-contacts-list-item-label">Fax:</div>
                                                    <a href={`fax:${fax}`}>
                                                        <div className="a4-help-and-support-contacts-list-item-value">{fax}</div>
                                                    </a>
                                                </div>
                                                <div className="a4-help-and-support-contacts-list-item-icon">
                                                    <svg className="a4-svgsprite -printer text-secondary">
                                                        <Printer stroke={bootstrapService.primaryColor} />
                                                    </svg>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <Subscribe />
                                <Footer />
                            </div>
                        </div>
                    </>
                }
            </>
        )
    }
}

export default Help;