import React from 'react'
import { isMobileOnly } from 'react-device-detect'

class WhyUsItem extends React.Component {
    // Component
    render() {
        const { header, body, imageUrl } = this.props.data;

        return (
            <li className="a4-list-items-item">
                <div className="a4-list-items-item-image" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-duration="700">
                    <img src={imageUrl} alt="" />
                </div>
                <div className="a4-list-items-item-info">
                    <div className="a4-list-items-item-info">
                        <h3 className="a4-list-items-item-title" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-duration="1300">{header}</h3>
                        <p className="a4-list-items-item-description" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-duration="1400">{body}</p>
                    </div>
                </div>
            </li>
        )
    }
}

export default WhyUsItem;