import React from 'react'
import './Error.scss'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'

import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import BubbleButton from '../BubbleButton/BubbleButton'

import bootstrapService from '../../../services/BootstrapService'

class Error extends React.Component {
    render() {
        const errorCode = this.props.location.state ? this.props.location.state.code : "404"
        const errorTitle = this.props.location.state ? this.props.location.state.title : "Page not found"
        const errorDescription = this.props.location.state ? this.props.location.state.description : "Looks like we can't find this page right now.<br /> You can visit our home page instead."
        const errorImg = bootstrapService.data ? bootstrapService.data.site.errorImageUrl : "";

        return (
            <>
                <Navbar ref={navbar => this.navbar = navbar} clickTry={this.clickTryForFree} />
                <div className="a4-view">
                    <div className="a4-layout a4-error">
                        <div className="a4-error-content">
                            <div className={"a4-error-exposition -highlighted"}>
                                <div className="a4-error-exposition-container">
                                    <div className="a4-error-exposition-content">
                                        <div className="a4-error-exposition-info">
                                            <h1 className="a4-error-exposition-code" data-aos={isMobileOnly ? undefined : "fade-up"}>{errorCode}</h1>
                                            <h3 className="a4-error-exposition-title" data-aos={isMobileOnly ? undefined : "fade-up"}><span className="text-primary">{errorTitle}</span></h3>
                                            <p className="a4-error-exposition-description" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-duration="1300" dangerouslySetInnerHTML={{ __html: errorDescription}}></p>
                                            <div className="a4-error-exposition-control" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-duration="1400">
                                                <Link to="/">
                                                    <BubbleButton className="-primary">Back to home page</BubbleButton>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="a4-error-exposition-illustration">
                                            <img src={errorImg} data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-easing="ease-out-quad" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(Error);