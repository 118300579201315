import React from 'react'
import './List.scss'
import { Link } from 'react-router-dom'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { isMobileOnly } from 'react-device-detect'

import BubbleButton from '../../shared/BubbleButton/BubbleButton'

class List extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // GSAP
        gsap.registerPlugin(ScrollTrigger);

        // Refs
        this.body = null;
        this.info = null;
    }

    // Lifecycle
    componentDidMount() {
        this.setupListScroll()
    }

    // Helpers
    setupListScroll() {
        ScrollTrigger.matchMedia({
            "(min-width: 768px)": () => {
                ScrollTrigger.create({
                    trigger: this.body,
                    pin: this.info,
                    start: () => `top center`,
                    end: () => `bottom center+=${this.info ? this.info.offsetHeight : 0}px`,
                    scrub: true,
                });
            },
        });
    }

    // Component
    render() {
        const { headers, body, actionLabel, actionLink } = this.props.data;
        const type = this.props.type;
        const highlighted = this.props.highlighted;

        return (
            <div className={("a4-list -" + type) + (highlighted ? " -highlighted" : "")}>
                <div className="a4-list-container">
                    <div className="a4-list-content">
                        <div className="a4-list-body" ref={div => this.body = div}>
                            <div className="a4-list-info" ref={div => this.info = div}>
                                <h2 className="a4-list-title" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                    {headers[0]} <span className="text-primary">{headers[1]}</span> {headers[2]}
                                </h2>
                                <p className="a4-list-description" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-duration="1300">{body}</p>
                                {
                                    actionLink && 
                                    <div className="a4-list-info-control" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-duration="1400">
                                        <Link to={actionLink}>
                                            <BubbleButton className="-primary">{actionLabel}</BubbleButton>
                                        </Link>
                                    </div>
                                }
                            </div>
                            <ul className="a4-list-items">
                                {this.props.children}
                            </ul>
                            {
                                actionLink && 
                                <div className="a4-list-control" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                    <Link to={actionLink}>
                                        <BubbleButton className="-primary">{actionLabel}</BubbleButton>
                                    </Link>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default List;