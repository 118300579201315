const recaptchaCallbackName = 'setRecaptchaReady';
const ready = new Promise(resolve => window[recaptchaCallbackName] = resolve);

class RecaptchaService {

    constructor() {
        this._ready = false;
        this._readyCallbacks = [];
        this._siteKey = null;
        
        ready.then(() => {
            this._ready = true;
            //console.log('recaptcha3 ready...');
            if (this._readyCallbacks.length > 0) {
                this._readyCallbacks.forEach(cb => {
                    window.grecaptcha.render(cb.element, {
                        siteKey: this._siteKey,
                        theme: cb.theme,
                        callback: cb.callback
                    });
                });
                this._readyCallbacks = [];
            }
        });
    }

    get siteKey() { return this._siteKey; }
    set siteKey(value) {
        //console.log('set siteKey...', value);
        if (!this._ready && !this._siteKey) {
            this._siteKey = value;

            let script = document.createElement('script');
            script.src = `https://www.google.com/recaptcha/api.js?onload=${recaptchaCallbackName}&render=${this._siteKey}`;
            script.async = true;
            script.defer = true;
            document.head.appendChild(script);
        }
    }
   
    getToken(action = 'default') {
        if (this._ready) {
            //console.log('recaptcha3 getting token...');
            return window.grecaptcha.execute(this.siteKey, { action: action });
        } 
        else {
            console.log('recaptcha3 not yet ready...');
            return new Promise((resolve, reject) => {
                if (this._ready) {
                    //console.log('recaptcha3 now ready resolving...');
                    resolve(window.grecaptcha.execute(this.siteKey, { action: action }));
                } else {
                    //console.log('recaptcha3 not yet ready rejecting...');
                    reject(new Error('recaptcha3 not ready'));
                }
            });
        }
    }
}

const recaptchaService = new RecaptchaService();
export default recaptchaService;