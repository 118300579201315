import React from 'react'
import { Link } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'

import Navbar from '../shared/Navbar/Navbar'
import Footer from '../shared/Footer/Footer'
import BubbleButton from '../shared/BubbleButton/BubbleButton'
import { ReactComponent as Error } from '../../assets/error.svg'

import formService from '../../services/FormService'
import memberService from '../../services/MemberService'
import recaptchaService from '../../services/RecaptchaService'

class Login extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.form = null;

        // This Binding
        this.submitForm = this.submitForm.bind(this);
    }

    // Lifecycle
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    // Event Handlers
    submitForm(e) {
        e.preventDefault();

        const isFormValid = formService.validate(this.form);
        if (!isFormValid) return;

        formService.showLoadingButton(this.form);

        const email = e.target.email.value.trim();
        const password = e.target.password.value;

        let memberRequest = {email, password}

        recaptchaService.getToken("login")
        .then(token => {
            memberRequest.recaptchaToken = token;
            
            memberService.login(memberRequest)
            .then(json => {
                if (json && json.succeeded) {
                    memberService.goToMembers();
                }
                else {
                    formService.hideAllErrors(this.form);
                    formService.hideLoadingButton(this.form);
                    if (json && json.errors && json.errors.length) {
                        formService.showFormError(this.form, json.errors[0]);
                    }
                    else {
                        formService.showFormError(this.form, 'Sorry, there was an error');
                    }
                }
            });
        });
    }

    // Component
    render() {
        return (
            <>
                <Navbar activeLink="login" />
                <div className="a4-view">
                    <main className="a4-layout a4-auth -login">
                        <div className="a4-auth-container -login">
                            <section className="a4-auth-item-container">
                                <div className="a4-auth-item-shadow"></div>
                                <div className="a4-auth-item-content" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                    <h1 className="a4-auth-item-title">Login</h1>
                                    <form className="a4-auth-form" ref={form => this.form = form} onSubmit={this.submitForm}>
                                        <div className="a4-input">
                                            <input name="email" type='email' required placeholder="E-mail address" />
                                            <label htmlFor="email">E-mail address</label>
                                            <span className="a4-input-error">Enter a valid e-mail address</span>
                                            <svg className="a4-svgsprite -error text-secondary">
                                                <Error />
                                            </svg>
                                        </div>
                                        <div className="a4-input">
                                            <input name="password" placeholder="Password" required type="password" />
                                            <label htmlFor="password">Password</label>
                                            <span className="a4-input-error">Enter a valid password</span>
                                            <svg className="a4-svgsprite -error text-secondary">
                                                <Error />
                                            </svg>
                                        </div>
                                        <p className="a4-form-error"></p>
                                        <section className="a4-auth-form-control">
                                            <BubbleButton className="a4-form-submit -primary" type="submit" formNoValidate>
                                                <b>Login</b>
                                            </BubbleButton>
                                            <Link to="/forgot-password">
                                                <b>Forgot your password?</b>
                                            </Link>
                                        </section>
                                    </form>
                                </div>
                            </section>
                            <aside className="a4-auth-additional" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-delay='100'>
                                <span>
                                    Don’t have an Account? &nbsp;
                                    <Link to="/register" className="text-primary">Sign up now!</Link>
                                </span>
                            </aside>
                        </div>
                        <Footer />
                    </main>
                </div>
            </>
        )
    }
}

export default Login;