import React from 'react'
import './Pricing.scss'
import './Plans.scss'
import $ from 'jquery'
import 'select2'
import { debounce } from '../../utils/debounce'
import { getKey } from '../../utils/key'
import { isMobileOnly } from 'react-device-detect'

import Navbar from '../shared/Navbar/Navbar'
import Membership from './Plans/Membership'
import IndividualCourse from './Plans/IndividualCourse'
import CourseBundle from './Plans/CourseBundle'
import GroupPlan from './Plans/GroupPlan'
import Webinar from './Plans/Webinar'
import Pages from '../shared/Pages/Pages'
import Empty from '../shared/Empty/Empty'
import Subscribe from '../shared/Subscribe/Subscribe'
import Footer from '../shared/Footer/Footer'
import { ReactComponent as FilterBars } from '../../assets/filter-bars.svg'
import { ReactComponent as Error } from '../../assets/error.svg'
import { ReactComponent as Cancel } from '../../assets/cancel.svg'

import bootstrapService from '../../services/BootstrapService'

class Pricing extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.filterMenu = null;
        this.planFilter = null;
        this.mobileFilterMenu = null;
        this.mobilePlanFilter = null;
        this.mobileSubmitButton = null;
        this.plansList = null;

        // State
        this.state = {
            currentPlan: this.props.match.params.plan,
            searchFilter: null,
            sortFilter: null,
            pageNumber: 0,
        }

        // Event Binding
        this.openMobileFilterMenu = this.openMobileFilterMenu.bind(this);
        this.closeMobileFilterMenu = this.closeMobileFilterMenu.bind(this);
        this.clickMobileFilterItem = this.clickMobileFilterItem.bind(this);
        this.clickMobileFilterSubmit = this.clickMobileFilterSubmit.bind(this);
        this.changePlansPage = this.changePlansPage.bind(this);
        this.searchCourses = debounce(this.searchCourses.bind(this), 400);
        this.searchCoursesMobile = debounce(this.searchCoursesMobile.bind(this), 400);
    }

    // Lifecyle
    componentDidMount() {
        window.scrollTo(0, 0);
        this.setupSelects();
        this.setupMobileFilter();
    }

    componentDidUpdate() {
        this.updateSelects();
        this.updateCurrentPlan();
    }

    // Helpers
    setupSelects() {
        $('.a4-select select').select2({
            minimumResultsForSearch: -1,
        });

        $('.a4-select select').on("change", (e, shouldIgnore, shouldIgnoreHistory) => {
            if (!shouldIgnore) {
                const { filterType } = e.currentTarget.dataset;
                this.setMobileFilterActive(filterType, e.currentTarget.value);
                
                if (filterType === "category") {
                    if (this.state.currentPlan !== e.currentTarget.value) {
                        this.filterPlans(e.currentTarget.value)

                        if (!shouldIgnoreHistory) {
                            this.props.history.push(`/pricing/${e.currentTarget.value}`);
                        }
                    }
                }
                else if (filterType === "sort") {
                    this.sortPlans(e.currentTarget.value)
                }
            }
        });

        $(this.planFilter).children().each((index, plan) => {
            if (plan.value === this.state.currentPlan) {
                $(this.planFilter).val(plan.value);
                $(this.planFilter).trigger("change", [false, false]);
            }
        });
    }

    updateSelects() {
        if (!$('.a4-select select').first().hasClass("select2-hidden-accessible")) {
            this.setupSelects();
        }
    }

    updateCurrentPlan() {
        // Back Button Clicked
        window.onpopstate = (e) => {
            if (this.props.match.params.plan !== this.state.currentPlan) {
                $(this.planFilter).val(this.props.match.params.plan);
                $(this.planFilter).trigger("change", [false, true]);
            }
        } 
    }

    setMobileFilterActive(filterType, filterValue) {
        const filter = $(this.mobileFilterMenu).find(`.a4-mobile-filter-items-item[data-filter-type='${filterType}'][data-value='${filterValue}']`);
        filter.addClass("-active");
        filter.siblings(".-active").removeClass("-active");

        const select = $(this.filterMenu).find(`select[data-filter-type='${filterType}']`);
        select.val(filter.data().value);
        select.trigger("change", true);
    }

    setupMobileFilter() {
        $(this.mobilePlanFilter).children().each((index, plan) => {
            if (plan.dataset.value === this.path) {
                plan.classList.add("-active")
            }
        });
    }

    filterPlans(filterOption) {
        this.enableMobileSpinner();
        this.setState({
            currentPlan: filterOption !== "" ? filterOption : null,
        }, this.disableMobileSpinner())
    }

    sortPlans(sortOption) {
        this.enableMobileSpinner();
        this.setState({
            sortFilter: sortOption,
        }, this.disableMobileSpinner());
    }

    enableMobileSpinner() {
        this.mobileSubmitButton.setAttribute("disabled", true);
        this.mobileSubmitButton.classList.add("-loading");
    }

    disableMobileSpinner() {
        if (this.mobileSubmitButton) {
            this.mobileSubmitButton.removeAttribute("disabled");
            this.mobileSubmitButton.classList.remove("-loading");
        }
    }

    // Event Handlers
    openMobileFilterMenu() {
        document.body.classList.add("overflow-hidden");
        this.mobileFilterMenu.classList.add("-open");
        this.disableMobileSpinner();
    }

    closeMobileFilterMenu() {
        document.body.classList.remove("overflow-hidden");
        this.mobileFilterMenu.classList.remove("-open");
    }

    clickMobileFilterItem(e) {
        this.enableMobileSpinner();

        const { filterType, value } = e.currentTarget.dataset;
        this.setMobileFilterActive(filterType, value);

        setTimeout((filterType, value) => {
            if (filterType === "category") {
                this.filterPlans(value)
                this.props.history.push(`/pricing/${value}`);
            }
            else if (filterType === "sort") {
                this.sortPlans(value);
            }
        }, 500, filterType, value);
    }

    clickMobileFilterSubmit() {
        this.closeMobileFilterMenu();
    }

    changePlansPage(e, page) {
        window.scrollTo(0, 0);
        this.setState( { pageNumber: page-1 });
    }

    searchCourses(e) {
        $(this.mobileFilterMenu).find(".a4-input").find("input").val(e.target.value)
        this.setState({
            searchFilter: e.target.value !== "" ? e.target.value.toLowerCase() : null,
        });
    }

    searchCoursesMobile(e) {
        this.enableMobileSpinner();
        $(this.filterMenu).find(".a4-input").find("input").val(e.target.value)
        
        setTimeout(() => {
            this.setState({
                searchFilter: e.target.value !== "" ? e.target.value.toLowerCase() : null,
            }, this.disableMobileSpinner());
        }, 600);
    }

    sortCourses(a, b) {
        //sort by course.order (first)
        let aval = typeof a.order !== 'undefined' ? a.order : null;
        let bval = typeof b.order !== 'undefined' ? b.order : null;
        if (aval === bval) {
            //sort by course.title (second, when order is same)
            if (a.title === b.title) {
                return 0;
            }
            return a.title < b.title ? -1 : 1;
        }
        return aval > bval ? -1 : 1;
    }

    sortPassTypes(a, b) {
        //sort by passType.order (first)
        let aval = typeof a.order !== 'undefined' ? a.order : null;
        let bval = typeof b.order !== 'undefined' ? b.order : null;
        if (aval === bval) {
            //sort by passType.name (second, when order is same)
            if (a.name === b.name) {
                return 0;
            }
            return a.name < b.name ? -1 : 1;
        }
        return aval > bval ? -1 : 1;
    }

    sortBundleTypes(a, b) {
        //sort by bundleType.order (first)
        let aval = typeof a.order !== 'undefined' ? a.order : null;
        let bval = typeof b.order !== 'undefined' ? b.order : null;
        if (aval ===bval) {
            //sort by bundleType.name (second, when order is same)
            if (a.name === b.name) {
                return 0;
            }
            return a.name < b.name ? -1 : 1;
        }
        return aval > bval ? -1 : 1;
    }

    sortGroupTypes(a, b) {
        //sort by groupType.order (first)
        let aval = typeof a.order !== 'undefined' ? a.order : null;
        let bval = typeof b.order !== 'undefined' ? b.order : null;
        if (aval === bval) {
            //sort by groupType.name (second, when order is same)
            if (a.name === b.name) {
                return 0;
            }
            return a.name < b.name ? -1 : 1;
        }
        return aval > bval ? -1 : 1;
    }

    // Data
    getPlans() {
        let plans = null;
        let sorter = null;

        if (bootstrapService.data) {
            if (this.state.currentPlan === "memberships") {
                plans = bootstrapService.data.passTypes;
                sorter = this.sortPassTypes;
            }
            else if (this.state.currentPlan === "individual-courses") {
                plans = bootstrapService.data.courseCatalog.courses.filter(c => c.hasOwnProperty("item"));
                sorter = this.sortCourses;
            }
            else if (this.state.currentPlan === "course-bundles") {
                plans = bootstrapService.data.courseBundleTypes;
                sorter = this.sortBundleTypes;
            }
            else if (this.state.currentPlan === "group-plans") {
                plans = bootstrapService.data.groupTypes;
                sorter = this.sortGroupTypes;
            }
        }

        if (plans && this.state.searchFilter) {
            plans = plans.filter(p => p.title.toLowerCase().search(this.state.searchFilter) > -1)
        }

        if (plans && this.state.sortFilter) {
            if (this.state.sortFilter === "default") {
                plans = plans.sort(sorter)
            }
            else if (this.state.sortFilter === "title") {
                plans = plans.sort(function(a, b) {
                    if (a.item.title === b.item.title) {
                        return 0;
                    }

                    return a.item.title < b.item.title ? -1 : 1;
                })
            }
            else if (this.state.sortFilter === "price") {
                plans = plans.sort(function(a, b) {
                    if (a.item.price === b.item.price) {
                        return 0;
                    }

                    return a.item.price < b.item.price ? -1 : 1;
                })
            }
            else {
                console.log("INVALID SORT OPTION")
            }
        }

        return plans;
    }

    getCurrentPlanType() {
        if (this.state.currentPlan === "memberships") {
            return "Memberships"
        }
        else if (this.state.currentPlan === "individual-courses") {
            return "Courses"
        }
        else if (this.state.currentPlan === "course-bundles") {
            return "Bundles"
        }
        else if (this.state.currentPlan === "group-plans") {
            return "Group Plans"
        }

        return "Plans";
    }

    // Component
    render() {
        const pricingData =  bootstrapService.data.landing.pages.find(p => p.name === "pricing");

        const header = pricingData ? pricingData.header : null;
        const body = pricingData ? pricingData.bodyText : null;
        const categoryFilters = pricingData ? pricingData.itemsFilter : null;
        const sortOptions = pricingData ? pricingData.sortOptions : null;

        const plans = this.getPlans();
        const pagedPlans = plans ? plans.slice(10*this.state.pageNumber, 10*this.state.pageNumber+10) : null;

        const planType = this.getCurrentPlanType();

        const renderKey = getKey();

        return (
            <>
                {
                    pricingData &&
                    <>
                        <Navbar backgroundColor="-primary-light" activeLink="pricing" />
                        <div className="a4-view">
                            <div className="a4-layout a4-pricing">
                                <header className="a4-pricing-header">
                                    <div className="a4-pricing-container">
                                        <section className="a4-pricing-page-title">
                                            <div className="a4-pricing-page-title-filler"></div>
                                            <h1 className="title" data-aos={isMobileOnly ? undefined : "fade-up"}>{header}</h1>
                                            <p className="description" data-aos={isMobileOnly ? undefined : "fade-up"}>{body}</p>
                                        </section>
                                        <section className="a4-pricing-filter-panel">
                                            <div className="a4-pricing-filter-panel-filler"></div>
                                            <div className="a4-pricing-filter-panel-container">
                                                <div className="a4-pricing-filter-panel-content" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-delay="50" data-aos-anchor-placement="top-bottom" ref={div => this.filterMenu = div}>
                                                    <div className="a4-pricing-filter-panel-section-1 md_hidden">
                                                        <button className="a4-btn" id="filter-menu-btn" onClick={this.openMobileFilterMenu}>
                                                            <i>
                                                                <svg className="a4-svgsprite">
                                                                    <FilterBars stroke={bootstrapService.secondaryColor} />
                                                                </svg>
                                                            </i>
                                                            <span>
                                                                Browse
                                                            </span>
                                                        </button>              
                                                    </div>
                                                    <div className="a4-pricing-filter-panel-section-2">
                                                        {
                                                            categoryFilters && categoryFilters.length > 0 &&
                                                            <div className="filter-item" id="category-filter">
                                                                <div className="a4-select">
                                                                    <select data-filter-type="category" ref={select => this.planFilter = select}>
                                                                        {
                                                                            categoryFilters.map((filter, index) => {
                                                                                return <option value={filter.replace(/\s+/g, "-").trim().toLowerCase()} key={index}>{filter}</option>
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>  
                                                        }
                                                        {
                                                            this.state.currentPlan === "individual-courses" &&
                                                            <div className="a4-input -search filter-item" data-aos={isMobileOnly ? undefined : "fade"}>
                                                                <input required placeholder="Search" onChange={this.searchCourses} />
                                                                <label>Search</label>
                                                                <span className="a4-input-error"></span>
                                                                <svg className="a4-svgsprite -error text-secondary">
                                                                    <Error />
                                                                </svg>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="a4-pricing-filter-panel-section-3">
                                                        {
                                                            sortOptions && sortOptions.length > 0 &&
                                                            <>
                                                                <div className="a4-pricing-filter-panel-section-title">
                                                                    <h4>Sort</h4>
                                                                </div>
                                                                <div id="sort-filter">
                                                                    <div className="a4-select">
                                                                        <select data-filter-type='sort'>
                                                                            <option value="default" key={0}>Default</option>
                                                                            {
                                                                                sortOptions.map((option, index) => {
                                                                                    return <option value={option.replace(/\s+/g, "-").trim().toLowerCase()} key={index+1}>{option}</option>
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="a4-mobile-filter-menu" ref={div => this.mobileFilterMenu = div}>
                                                    <div className="a4-mobile-filter-container">
                                                        <div className="a4-mobile-filter-content">
                                                            <div className="a4-mobile-filter-top">
                                                                <div className="a4-mobile-filter-title">
                                                                    <h4>Browse</h4>
                                                                </div>
                                                                <div className="a4-mobile-filter-cancel" onClick={this.closeMobileFilterMenu}>
                                                                    <svg className="a4-svgsprite -cancel">
                                                                        <Cancel />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                            {
                                                                this.state.currentPlan === "individual-courses" &&
                                                                <div className="a4-mobile-filter-search">
                                                                    <div className="a4-input -search filter-item" data-aos={isMobileOnly ? undefined : "fade"}>
                                                                        <input required placeholder="Search" onChange={this.searchCoursesMobile} />
                                                                        <label>Search</label>
                                                                        <span className="a4-input-error"></span>
                                                                        <svg className="a4-svgsprite -error text-secondary">
                                                                            <Error />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className="a4-mobile-filter-list">
                                                                {
                                                                    categoryFilters && categoryFilters.length > 0 &&
                                                                    <>
                                                                        <div className="a4-mobile-filter-list-title">
                                                                            <h4>Category</h4>
                                                                        </div>
                                                                        <ul className="a4-mobile-filter-items" ref={ul => this.mobilePlanFilter = ul}>
                                                                            {
                                                                                categoryFilters.map((filter, index) => {
                                                                                    return <li className="a4-mobile-filter-items-item" data-filter-type="category" data-value={filter.replace(/\s+/g, "-").trim().toLowerCase()} key={index} onClick={this.clickMobileFilterItem}>
                                                                                        <span>{filter}</span>
                                                                                    </li>
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </>
                                                                }
                                                            </div>
                                                            <div className="a4-mobile-filter-list">
                                                                {
                                                                    sortOptions && sortOptions.length > 0 &&
                                                                    <>
                                                                        <div className="a4-mobile-filter-list-title">
                                                                            <h4>Sort</h4>
                                                                        </div>
                                                                        <ul className="a4-mobile-filter-items">
                                                                            <li className="a4-mobile-filter-items-item -active" data-filter-type="sort" data-value="default" key={0} onClick={this.clickMobileFilterItem}>
                                                                                <span>Default</span>
                                                                            </li>   
                                                                            {
                                                                                sortOptions.map((option, index) => {
                                                                                    return <li className="a4-mobile-filter-items-item" data-filter-type="sort" data-value={option.replace(/\s+/g, "-").trim().toLowerCase()} key={index+1} onClick={this.clickMobileFilterItem}>
                                                                                        <span>{option}</span>
                                                                                    </li>
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="a4-mobile-filter-control">
                                                            <button className="a4-btn -primary" ref={button => this.mobileSubmitButton = button} onClick={this.clickMobileFilterSubmit}>
                                                                Show &nbsp;
                                                                <span className="a4-mobile-filter-control-course-count">{plans ? plans.length : ""} &nbsp;{planType}</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </header>
                                <div className="a4-pricing-container">
                                    <section className="a4-pricing-memberships" key={renderKey}>
                                        <ul className="a4-pricing-list" ref={ul => this.plansList = ul}>
                                        {
                                            pagedPlans &&
                                            <>
                                                {
                                                    this.state.currentPlan === "memberships" &&
                                                    pagedPlans.map((membership, index) => {
                                                        return <Membership key={index} data={membership} />
                                                    })
                                                }
                                                {
                                                    this.state.currentPlan === "individual-courses" &&
                                                    pagedPlans.map((course, index) => {
                                                        if (course.schedules) {
                                                            return <Webinar key={index} data={course} />
                                                        }
                                                        else {
                                                            return <IndividualCourse key={index} data={course} />
                                                        }
                                                        
                                                    })
                                                }
                                                {
                                                    this.state.currentPlan === "course-bundles" &&
                                                    pagedPlans.map((bundle, index) => {
                                                        return <CourseBundle key={index} data={bundle} />
                                                    })
                                                }
                                                {
                                                    this.state.currentPlan === "group-plans" &&
                                                    pagedPlans.map((plan, index) => {
                                                        return <GroupPlan key={index} data={plan} />
                                                    })
                                                }
                                            </>
                                        }
                                        </ul>
                                        {
                                            plans && plans.length > 10 &&
                                            <Pages count={Math.ceil(plans.length/10)} page={this.state.pageNumber + 1} size="large" onChange={this.changePlansPage}/>
                                        }
                                        {
                                            this.state.searchFilter && plans.length === 0 &&
                                            <Empty name="pricing"/>
                                        }
                                    </section>
                                    <Subscribe />
                                    <Footer />
                                </div>
                            </div>
                        </div>
                    </>
                }
            </>
        )
    }
}

export default Pricing;