import React from 'react'
import './Spinner.scss'

class Spinner extends React.Component {
    // Component
    render() {
        return (
            <div className="a4-spinner" aria-hidden={true}></div>
        )
    }
}

export default Spinner;