import React from 'react'
import './Faq.scss'
import $ from 'jquery'
import { isMobileOnly } from 'react-device-detect'

import Navbar from '../shared/Navbar/Navbar'
import Subscribe from '../shared/Subscribe/Subscribe'
import Footer from '../shared/Footer/Footer'
import BubbleButton from '../shared/BubbleButton/BubbleButton'
import { ReactComponent as CheckMark } from '../../assets/check-mark.svg'
import { ReactComponent as Error } from '../../assets/error.svg'

import bootstrapService from '../../services/BootstrapService'
import formService from '../../services/FormService'
import faqService from '../../services/FaqService'
import recaptchaService from '../../services/RecaptchaService'

class Faq extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.accordianItems = new Array([]);
        this.questionForm = null;
        this.successMessage = null;

        // This Binding
        this.clickAccordianItem = this.clickAccordianItem.bind(this);
        this.submitQuestion = this.submitQuestion.bind(this);
    }

    // Lifecycle
    componentDidMount() {
        window.scrollTo(0, 0);
        this.setupAccordian();
    }

    componentDidUpdate() {
        this.setupAccordian();
    }

    // Helpers
    setupAccordian() {
        for (const item of this.accordianItems) {
            const paragraph = $(item).find(".a4-faq-topics-accordion-item-content-answer");
            const height = -(paragraph.height() + 20);
            paragraph.css("margin-top", height);
        }
    }

    closeItem(paragraph) {
        const height = -(paragraph.height() + 20);
        paragraph.css("margin-top", height);
    }

    closeAllItems() {
        for (const item of this.accordianItems) {
            if (item.classList.contains("-opened")) {
                const paragraph = $(item).find(".a4-faq-topics-accordion-item-content-answer");
                this.closeItem(paragraph);
                item.classList.remove("-opened");
            }
        }
    }

    // Event Handlers
    clickAccordianItem(e) {
        const item = e.currentTarget.parentElement;
        const paragraph = $(item).find(".a4-faq-topics-accordion-item-content-answer");

        if (!item.classList.contains("-opened")) {
            paragraph.show(0, () => {
                paragraph.css("margin-top", "");
            });
            this.closeAllItems();
        } 
        else {
            this.closeItem(paragraph);
        }

        item.classList.toggle("-opened");
    }

    submitQuestion(e) {
        e.preventDefault();

        const isFormValid = formService.validate(this.questionForm);
        if(!isFormValid) return;

        formService.showLoadingButton(this.questionForm);

        const email = e.target.email.value.trim();
        const question = e.target.question.value;

        let faqRequest = {email, question};

        recaptchaService.getToken("askFaq")
        .then(token => {
            faqRequest.recaptchaToken = token;

            faqService.askQuestion(faqRequest)
            .then(json => {
                if (json.succeeded) {
                    this.successMessage.classList.add("-visible");
                }
                else {
                    formService.hideAllErrors(this.questionForm);
                    formService.hideLoadingButton(this.questionForm);
                    formService.showInputError(this.questionForm, "question", json.errors[0]);
                }
            });
        });
    }
    
    // Component
    render() {
        const faqData = bootstrapService.data.landing.pages.find(p => p.name === "faq");

        const header = faqData ? faqData.header : null;
        const faqs = faqData ? faqData.faqs : null;

        return (
            <>
                {
                    faqData && 
                    <>
                        <Navbar />
                        <div className="a4-view">
                            <div className="a4-layout a4-faq">
                                <div className="a4-faq-container">
                                    <div className="a4-faq-page-title" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                        <div className="a4-faq-page-title-content">
                                            <h1 className="title">{header}</h1>
                                        </div>
                                    </div>
                                    <div className="a4-faq-topics">
                                        <div className="a4-faq-topics-content">
                                            <ul className="a4-faq-topics-accordion">
                                                {
                                                    faqs.map((faq, index) => {
                                                        return <li className="a4-faq-topics-accordion-item" data-aos={isMobileOnly ? undefined : "fade-up"} key={index} ref={li => this.accordianItems[index] = li}>
                                                            <div className="a4-faq-topics-accordion-item-head" onClick={this.clickAccordianItem}>
                                                                <h3 className="a4-faq-topics-accordion-item-head-title" dangerouslySetInnerHTML={{ __html: faq.question}}></h3> 
                                                                <span className="a4-faq-topics-accordion-item-head-icon">
                                                                    <div className="a4-dynamic-plus"></div>
                                                                </span>
                                                            </div>
                                                            <div className="a4-faq-topics-accordion-item-fill"></div>
                                                            <div className="a4-faq-topics-accordion-item-content">
                                                                <div className="a4-faq-topics-accordion-item-content-answer" dangerouslySetInnerHTML={{ __html: faq.answer}}></div>
                                                            </div>
                                                        </li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="a4-faq-question">
                                        <div className="a4-faq-question-content">
                                            <div className="a4-faq-question-card" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                                <div className="a4-faq-question-card-content">
                                                    <div className="a4-faq-question-card-title">
                                                        <h2><span className="primary-text">Did not find the answer</span> to your question?</h2>
                                                    </div>
                                                    <div className="a4-faq-question-card-form">
                                                        <form ref={form => this.questionForm = form} onSubmit={this.submitQuestion}>
                                                            <section className="a4-faq-question-card-form-section">
                                                                <div className="a4-form-group">
                                                                    <div className="a4-input">
                                                                        <textarea placeholder='Your question' required name='question'></textarea>
                                                                        <label htmlFor="question">Your question</label>
                                                                        <span className="a4-input-error"></span>
                                                                        <svg className="a4-svgsprite -error text-secondary">
                                                                            <Error />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                <div className="a4-form-group">
                                                                    <div className="a4-input">
                                                                        <input name="email" type='email' required placeholder="E-mail address" />
                                                                        <label htmlFor="email">E-mail address</label>
                                                                        <span className="a4-input-error"></span>
                                                                        <svg className="a4-svgsprite -error text-secondary">
                                                                            <Error />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                <div className="a4-form-control">
                                                                    <BubbleButton className="a4-form-submit -primary" type="submit" formNoValidate>
                                                                        Submit
                                                                    </BubbleButton>
                                                                </div>
                                                            </section>
                                                            <p className="a4-form-error">Cannot make faq request at this time</p>
                                                            <section className="a4-faq-question-card-form-section -success" ref={section => this.successMessage = section}>
                                                                <figure>
                                                                    <svg className="a4-svgsprite text-secondary">
                                                                            <CheckMark stroke={bootstrapService.primaryColor} />
                                                                        </svg>
                                                                </figure>
                                                                <h3><span className="text-primary">Thank you</span> for the question!</h3>
                                                                <p>We will answer you shortly.</p>
                                                            </section>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Subscribe />
                                <Footer />
                            </div>
                        </div>
                    </>
                }
            </>
        )
    }
}

export default Faq;