class HttpService {
    constructor() {
        this.apiToken = null;
        this.sessionId = null;
        this.refreshingApiToken = false;

        //client = cubert-landing
        this.hssEdClientId = '39b11001-4269-4b26-a138-db50c2bbdf69';
        this.hssEdClientSecret = '40015df8-380c-456e-abf1-254791cb9509';
        this.hssEdClientPlatform = 'web';

        let metaSiteName = document.querySelector('meta[name=hssedsitename]');
        if (metaSiteName) {
            this.siteName = metaSiteName.getAttribute('content');
            if (this.siteName.startsWith('@')) {
                this.siteName = "nursececlub.com";
                // NURSING = nursececlub.com accelerated-ce.com ohionursepracticeactce.com newyorkinfectioncontrolce.com paact31training.com cnaceu.com oregon-afh-ce.com michigan-implicit-bias-training.com
                // ACCOUNTING = cpelite.com, affordable-new-jersey-law-ethics.com
            }
        }

        let metaSessionId = document.querySelector('meta[name=hssedsessionid]');
        if (metaSessionId) {
            this.sessionId = metaSessionId.getAttribute('content');
            if (this.sessionId.startsWith('@'))
                this.sessionId = null;
        }

        let metaSlot = document.querySelector('meta[name=hssedslot]');
        if (metaSlot) {
            this.slot = metaSlot.getAttribute('content');
        }
        if (!this.slot || this.slot.startsWith('@')) {
            this.slot = 'prod';
        }

        let metaLocal = document.querySelector('meta[name=hssedlocal]');
        if (metaLocal) {
            this.local = metaLocal.getAttribute('content');
        }
        if (this.local) {
            if (this.local.startsWith('@')) {
                this.local = false;
            }
            else {
                this.local = this.local.toLowerCase() === "true";
            }
        } else {
            this.local = false;
        }

        if (this.local) {
            this.apiUrl = "http://localhost:7002";
        }
        else {
            switch (this.slot) {
                case 'dev':
                    this.apiUrl = "https://api-dev.hssed.com";
                    break;
                case 'staging':
                    this.apiUrl = "https://api-staging.hssed.com";
                    break;
                default:
                    this.apiUrl = "https://api.hssed.com";
                    break;
            }
        }

        let metaColor = document.querySelector('meta[name=hssedcolor]');
        if (metaColor) {
            this.color = metaColor.getAttribute('content');
            document.documentElement.style.setProperty('--spinner-color', this.color);
        }
        if (!this.color || this.color.startsWith('@')) {
            this.color = null; //DEFAULT COLOR
            document.documentElement.style.setProperty('--spinner-color', "#4AD5C6");
        }

        console.warn(`http-service.siteName = ${this.siteName}`)
        console.warn(`http-service.slot = ${this.slot}`)
        console.warn(`http-service.local = ${this.local}`)
        console.warn(`http-service.apiUrl = ${this.apiUrl}`)
    }

    setSession(apiToken, sessionId, expiresIn = 3599) {
        this.apiToken = apiToken;
        this.sessionId = sessionId;
        this.apiTokenExpire = (Date.now() / 1000) + expiresIn;

        console.log(`http-service.setSession: api token expires in ${expiresIn} seconds`);
    }

    refreshApiToken() {
        console.log('http-service.refreshApiToken...');

        this.refreshingApiToken = true;

        const url = `${this.apiUrl}/api/identity/token`;
        const headers = {
            "Content-Type": "application/json",
            "HssEdSiteName": this.siteName,
            "HssEdSessionId": this.sessionId,
            "HssEdClientId": this.hssEdClientId,
            "HssEdClientSecret": this.hssEdClientSecret,
            "HssEdClientPlatform": this.hssEdClientPlatform
        };
        const body = {};

        return this.post(url, headers, body, false)
            .then(json => {
                this.refreshingApiToken = false;
                if (json && json.succeeded) {
                    console.log('http-service.refreshApiToken: got new api token', json);
                    this.setSession(json.result.accessToken, this.sessionId, json.result.accessTokenLifetime);
                    return json;
                }
                console.error('http-service.refreshApiToken: cannot get api token', json);
                return json;
            })
            .catch(error => {
                this.refreshingApiToken = false;
                console.error('http-service.refreshApiToken: error in getting new api token...', error);
                return Promise.reject(error);
            });
    }

    verifyApiToken(verify = true) {
        if (verify) {
            if (this.apiToken) {
                let expireInSeconds = this.apiTokenExpire - (Date.now() / 1000);
                console.warn(`http-service.verifyApiToken: expires in ${expireInSeconds}`);
                if (expireInSeconds < 0) {
                    //api token has expired, so refresh
                    return this.refreshApiToken();
                }
                //api token has not expired
                return Promise.resolve({ succeeded: true });
            }
            console.error('http-service.verifyApiToken: missing apiToken');
            //no api token
            return Promise.resolve({ succeeded: false });
        }
        return Promise.resolve({ succeeded: true });
    }

    getResponse(response) {
        if (response) {
            if (response.ok)
                return response.json();
            console.error(`http-service.getResponse: ${response.statusText}`);
            return Promise.reject(response.statusText);
        }
        console.error(`http-service.getResponse: response is null`);
        return Promise.reject("Response is null");
    }

    get(url, headers, verify = true) {
        return this.verifyApiToken(verify)
            .then(result => {
                if (result && result.succeeded) {

                    //check if result is a token refresh, if so - need to update access token in headers
                    if (result.result && result.result.accessToken && headers && headers["Authorization"]) {
                        headers["Authorization"] = `Bearer ${httpService.apiToken}`;
                    }
                    headers["HssEdSiteName"] = this.siteName;
                    headers["HssEdClientId"] = this.hssEdClientId;
                    headers["HssEdClientSecret"] = this.hssEdClientSecret;
                    headers["HssEdClientPlatform"] = this.hssEdClientPlatform;

                    const requestOptions = {
                        method: "GET",
                        headers: headers,
                    }

                    return fetch(url, requestOptions)
                        .then(response => this.getResponse(response))
                        .catch(error => console.error(error))
                }
                console.error(result);
            })
            .catch(error => console.error(error))
    }

    post(url, headers, body, verify = true) {
        return this.verifyApiToken(verify)
            .then(result => {
                if (result && result.succeeded) {

                    //check if result is a token refresh, if so - need to update access token in headers
                    if (result.result && result.result.accessToken && headers && headers["Authorization"]) {
                        headers["Authorization"] = `Bearer ${httpService.apiToken}`;
                    }
                    headers["HssEdSiteName"] = this.siteName;
                    headers["HssEdClientId"] = this.hssEdClientId;
                    headers["HssEdClientSecret"] = this.hssEdClientSecret;
                    headers["HssEdClientPlatform"] = this.hssEdClientPlatform;

                    const requestOptions = {
                        method: "POST",
                        headers: headers,
                        body: JSON.stringify(body)
                    }

                    return fetch(url, requestOptions)
                        .then(response => this.getResponse(response))
                        .catch(error => {
                            console.error(error);
                            throw error;
                        });
                }
                console.error(result);
            })
            .catch(error => {
                console.error(error);
                throw error;
            });
    }

    postNoResponse(url, headers, body, verify = true) {
        return this.verifyApiToken(verify)
            .then(result => {
                if (result && result.succeeded) {

                    //check if result is a token refresh, if so - need to update access token in headers
                    if (result.result && result.result.accessToken && headers && headers["Authorization"]) {
                        headers["Authorization"] = `Bearer ${httpService.apiToken}`;
                    }
                    headers["HssEdSiteName"] = this.siteName;
                    headers["HssEdClientId"] = this.hssEdClientId;
                    headers["HssEdClientSecret"] = this.hssEdClientSecret;
                    headers["HssEdClientPlatform"] = this.hssEdClientPlatform;

                    const requestOptions = {
                        method: "POST",
                        headers: headers,
                        body: JSON.stringify(body)
                    }

                    return fetch(url, requestOptions)
                        .then(response => response)
                        .catch(error => console.error(error))
                }
                console.error(result);
            })
            .catch(error => console.error(error))
    }

    put(url, headers, body, verify = true) {
        return this.verifyApiToken(verify)
            .then(result => {
                if (result && result.succeeded) {

                    //check if result is a token refresh, if so - need to update access token in headers
                    if (result.result && result.result.accessToken && headers && headers["Authorization"]) {
                        headers["Authorization"] = `Bearer ${httpService.apiToken}`;
                    }
                    headers["HssEdSiteName"] = this.siteName;
                    headers["HssEdClientId"] = this.hssEdClientId;
                    headers["HssEdClientSecret"] = this.hssEdClientSecret;
                    headers["HssEdClientPlatform"] = this.hssEdClientPlatform;

                    const requestOptions = {
                        method: "PUT",
                        headers: headers,
                        body: JSON.stringify(body)
                    }

                    return fetch(url, requestOptions)
                        .then(response => this.getResponse(response))
                        .catch(error => console.error(error))
                }
                console.error(result);
            })
            .catch(error => console.error(error))
    }

    delete(url, headers, body, verify = true) {
        return this.verifyApiToken(verify)
            .then(result => {
                if (result && result.succeeded) {

                    //check if result is a token refresh, if so - need to update access token in headers
                    if (result.result && result.result.accessToken && headers && headers["Authorization"]) {
                        headers["Authorization"] = `Bearer ${httpService.apiToken}`;
                    }
                    headers["HssEdSiteName"] = this.siteName;
                    headers["HssEdClientId"] = this.hssEdClientId;
                    headers["HssEdClientSecret"] = this.hssEdClientSecret;
                    headers["HssEdClientPlatform"] = this.hssEdClientPlatform;

                    const requestOptions = {
                        method: "DELETE",
                        headers: headers,
                        body: JSON.stringify(body)
                    }

                    return fetch(url, requestOptions)
                        .then(response => this.getResponse(response))
                        .catch(error => console.error(error))
                }
                console.error(result);
            })
            .catch(error => console.error(error))
    }
}

const httpService = new HttpService();
export default httpService;