import React from 'react'
import './FullNameInput.scss'
import $ from 'jquery'

import { ReactComponent as Error } from '../../../assets/error.svg'

class FullNameInput extends React.Component {

    constructor(props) {
        super(props);

        // Refs
        this.fullNameInput = null;
        this.fullNameGroup = null;
        this.firstNameInput = null;
        this.middleNameInput = null;
        this.lastNameInput = null;
        this.lastNameInputTextField = null;

        // State
        this.state = {
            useFullNameInput: true,
            firstName: this.props.firstName,
            middleName: this.props.middleName,
            lastName: this.props.lastName,
        }

        // This Binding
        this.checkFullNameInput = this.checkFullNameInput.bind(this);
    }

    // Lifecycle
    componentDidMount() {
        this.setupNameInputs();
    }

    // Helpers
    setupNameInputs() {
        // Timeout hack ensures function runs after Render (so that REFs are set)
        setTimeout(() => {
            if (this.state.firstName && this.state.middleName && this.state.lastName) {
                console.log("full name")
                // Change Name Inputs
                this.changeNameInputs();

                // Update Parent Component (Form)
                this.props.ignoreFullName();

                // Update State
                this.setState({ useFullNameInput: false });
            }
        }, 0);
    }

    changeNameInputs() {
        // Hide Full Name
        this.fullNameInput.classList.add("-disabled");
        this.fullNameInput.style.display = "none";

        // Show First Name
        this.firstNameInput.classList.remove("-disabled");
        this.firstNameInput.style.display = "block";

        // Show Middle Name
        this.middleNameInput.classList.remove("-disabled");
        this.middleNameInput.style.display = "block";

        // Show Last Name
        this.lastNameInput.classList.remove("-disabled");
        this.lastNameInput.style.display = "block";
    }

    // Event Handlers
    checkFullNameInput(e) {
        if (e.currentTarget.value.split(" ").length > 2) {
            // Change Name Inputs
            this.changeNameInputs();

            // Update Parent Component (Form)
            this.props.ignoreFullName();

            // Update State
            this.setState({
                useFullNameInput: false,
                firstName: e.currentTarget.value.split(" ")[0],
                middleName: e.currentTarget.value.split(" ")[1],
            }, () => {
                this.lastNameInputTextField.value = "";
                this.lastNameInputTextField.focus();
            });
        }
    }

    hideInputs() {
        if (this.state.useFullNameInput) {
            this.fullNameInput.classList.add("-disabled");
            $(this.fullNameInput).hide(200);
        }
        else {
            this.firstNameInput.classList.add("-disabled");
            this.middleNameInput.classList.add("-disabled");
            this.lastNameInput.classList.add("-disabled");
            $(this.fullNameGroup).hide(200);
        }
    }

    showInputs() {
        if (this.state.useFullNameInput) {
            $(this.fullNameInput).show(0, () => {
                this.fullNameInput.classList.remove("-disabled");
            });
        }
        else {
            $(this.fullNameGroup).show(0, () => {
                this.firstNameInput.classList.remove("-disabled");
                this.middleNameInput.classList.remove("-disabled");
                this.lastNameInput.classList.remove("-disabled");
            });
        }
    }

    getFullName() {
        if (this.state.firstName && this.state.middleName && this.state.lastName) {
            return this.state.firstName + " " + this.state.middleName + " " + this.state.lastName;
        }
        else if (this.state.firstName && this.state.lastName) {
            return this.state.firstName + " " + this.state.lastName
        }
        else {
            return "";
        }
    }

    render() {
        const fullName = this.getFullName();

        return (
            <>
                <div className="a4-input" ref={div => this.fullNameInput = div}>
                    <input name="fullName" required placeholder="Full Name" defaultValue={fullName} onChange={this.checkFullNameInput}/>
                    <label htmlFor="fullName">Full Name</label>
                    <span className="a4-input-error">Enter your full name</span>
                    <svg className="a4-svgsprite -error text-secondary">
                        <Error />
                    </svg>
                </div>
                <div className="a4-input-group" ref={div => this.fullNameGroup = div}>
                    <div className="a4-input firstName -disabled" ref={div => this.firstNameInput = div}>
                        <input name="firstName" required placeholder="First Name" defaultValue={this.state.firstName} />
                        <label htmlFor="firstName">First Name</label>
                        <span className="a4-input-error"></span>
                        <svg className="a4-svgsprite -error text-secondary">
                            <Error />
                        </svg>
                    </div>
                    <div className="a4-input middleName -disabled" ref={div => this.middleNameInput = div}>
                        <input name="middleName" placeholder="Middle Name" defaultValue={this.state.middleName} />
                        <label htmlFor="middleName">Middle Name</label>
                        <span className="a4-input-error"></span>
                        <svg className="a4-svgsprite -error text-secondary">
                            <Error />
                        </svg>
                    </div>
                    <div className="a4-input lastName -disabled" ref={div => this.lastNameInput = div}>
                        <input name="lastName" required placeholder="Last Name" defaultValue={this.state.lastName} ref={input => this.lastNameInputTextField = input} />
                        <label htmlFor="lastName">Last Name</label>
                        <span className="a4-input-error"></span>
                        <svg className="a4-svgsprite -error text-secondary">
                            <Error />
                        </svg>
                    </div>
                </div>
            </>
        )
    }
}

export default FullNameInput;