import React from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import { isMobileOnly } from 'react-device-detect'

import Navbar from '../shared/Navbar/Navbar'
import Footer from '../shared/Footer/Footer'
import BubbleButton from '../shared/BubbleButton/BubbleButton'
import { ReactComponent as Error } from '../../assets/error.svg'

import formService from '../../services/FormService'
import memberService from '../../services/MemberService'
import recaptchaService from '../../services/RecaptchaService'

class ForgotPassword extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.content = null;
        this.paragraph = null;
        this.form = null;

        // This Binding
        this.submitForm = this.submitForm.bind(this);
    }

    // Lifecycle
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    // Event Handlers
    submitForm(e) {
        e.preventDefault();

        const isFormValid = formService.validate(this.form);
        if(!isFormValid) return;

        formService.showLoadingButton(this.form);

        const email = e.target.email.value.trim();

        let forgotPasswordRequest = {email};

        recaptchaService.getToken("forgotPassword")
        .then(token => {
            forgotPasswordRequest.recaptchaToken = token;

            memberService.forgotPassword(forgotPasswordRequest)
            .then(json => {
                if (json.succeeded) {
                    this.content.classList.add("-success");
                    $(this.paragraph).animate({ opacity: 0 }, 300, function () {
                        $(this)
                            .html(
                                "We’ve emailed you a link to reset your password. If you don’t see it, please check your spam folder before getting in touch!"
                            )
                            .animate({ opacity: 1 }, 100);
                    });
                }
                else {
                    this.content.classList.add("-success");
                    $(this.paragraph).animate({ opacity: 0 }, 300, function () {
                        $(this)
                            .html(
                                "If there is an account with your email address then we have emailed you a link to reset your password. If you don’t see it, please check your spam folder before getting in touch!"
                            )
                            .animate({ opacity: 1 }, 100);
                    });

                    // formService.hideAllErrors(this.form);
                    // formService.hideLoadingButton(this.form);

                    // if (json.errors[0] === "Cannot get user") {
                    //     formService.showInputError(this.form, "email", "This email address is not recognized");
                    // }
                    // else {
                    //     formService.showFormError(this.form, json.errors[0]);
                    // } 
                }
            });
        });
    }

    // Component
    render() {
        return (
            <>
                <Navbar />
                <div className="a4-view">
                    <main className="a4-layout a4-auth">
                        <div className="a4-auth-container -login">
                            <section className="a4-auth-item-container">
                                <div className="a4-auth-item-shadow"></div>
                                <div className="a4-auth-item-content" data-aos={isMobileOnly ? undefined : "fade-up"} ref={div => this.content = div}>
                                    <h1 className="a4-auth-item-title"><span className="text-primary">Forgot</span> your password?</h1>
                                    <p ref={p => this.paragraph = p}>Enter your account email, you will be sent<br></br>a link to reset your password.</p>
                                    <form className="a4-auth-form" ref={form => this.form = form} onSubmit={this.submitForm}>
                                        <div className="a4-input">
                                            <input name="email" required placeholder="E-mail address" />
                                            <label htmlFor="email">E-mail address</label>
                                            <span className="a4-input-error" ref={span => this.emailError = span}>Enter a valid e-mail address</span>
                                            <svg className="a4-svgsprite -error text-secondary">
                                                <Error />
                                            </svg>
                                        </div>
                                        <p className="a4-form-error"></p>
                                        <section className="a4-auth-form-control">
                                            <BubbleButton className="a4-form-submit -primary" type="submit" formNoValidate>
                                                <b>Send me reset link</b>
                                            </BubbleButton>
                                        </section>
                                    </form>
                                </div>
                            </section>
                            <aside className="a4-auth-additional" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-delay='100'>
                                <span>
                                    Already have an Account? &nbsp;
                                    <Link to="/login" className="text-primary">Login</Link>
                                </span>
                            </aside>
                        </div>
                        <Footer />
                    </main>
                </div>
            </>
        )
    }
}

export default ForgotPassword;