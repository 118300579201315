import React from 'react'
import gsap from 'gsap'

class BubbleButton extends React.Component {
    // Event Handlers
    handleBubble(e) {
        const bubble = e.target.querySelector('span.a4-btn-bubble span')
        if(bubble) {
            const rect = e.target.getBoundingClientRect();
            const y = e.clientY - rect.top;
            const x = e.clientX - rect.left;

            gsap.to(bubble, {
                top: y,
                left: x
            }, 0);
        }
    }

    // Component
    render() {
        return (
            <button className={"a4-btn -bubble " + this.props.className} 
                    onMouseEnter={this.handleBubble} 
                    onMouseLeave={this.handleBubble} 
                    ref={this.props.innerRef} 
                    type={this.props.type} 
                    formNoValidate={this.props.formNoValidate} 
                    onClick={this.props.onClick}>
                {this.props.children}
                <span className="a4-btn-bubble">
                    <span></span>
                </span>
            </button>
        )
    }
}

export default React.forwardRef((props, ref) => <BubbleButton 
  innerRef={ref} {...props}
/>);