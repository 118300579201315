import React from 'react'
import { isMobileOnly } from 'react-device-detect'

class HowItWorksItem extends React.Component {
    // Component
    render() {
        const { header, body } = this.props.data;
        
        return (
            <li className="a4-list-items-item" data-aos={isMobileOnly ? undefined : "fade-up"}>
                <div className="a4-list-items-item-number"></div>
                <div className="a4-list-items-item-info">
                    <h3 className="a4-list-items-item-title">{header}</h3>
                    <p className="a4-list-items-item-description">{body}</p>
                </div>
            </li>
        )
    }
}

export default HowItWorksItem;