import React from 'react'
import './TryForFree.scss'
import { Link } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'
 
import FullNameInput from '../../shared/FullNameInput/FullNameInput'
import BubbleButton from '../../shared/BubbleButton/BubbleButton'
import { ReactComponent as Error } from '../../../assets/error.svg'

import memberService from '../../../services/MemberService'
import formService from '../../../services/FormService'
import recaptchaService from '../../../services/RecaptchaService'
import { MemberType } from '../../../services/MemberService'

class TryForFree extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.form = null;

        // State
        this.state = {
            useFullName: true,
        }

        // This Binding
        this.ignoreFullName = this.ignoreFullName.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    // Event Handlers
    ignoreFullName() {
        this.setState({ useFullName: false });
    }

    submitForm(e) {
        e.preventDefault();

        const isFormValid = formService.validate(this.form);

        const email = e.target.email.value.trim();
        const password = e.target.password.value;
        const confirm = e.target.confirm.value;
        
        let firstName = "";
        let middleName = "";
        let lastName = "";

        if (this.state.useFullName) {
            firstName = e.target.fullName.value.split(" ")[0];
            lastName = e.target.fullName.value.split(" ")[1];
        }
        else {
            firstName = e.target.firstName.value;
            middleName = e.target.middleName.value;
            lastName = e.target.lastName.value;
        }

        if (password !== confirm) {
            formService.showInputError(this.form, "confirm", "This password does not match");
            formService.hideLoadingButton(this.form);
            return;
        }

        if(!isFormValid) return;

        formService.showLoadingButton(this.form);

        let memberRequest = {email, password, firstName, middleName, lastName};

        recaptchaService.getToken("register")
        .then(token => {
            memberRequest.recaptchaToken = token;
            memberRequest.memberType = MemberType.TryForFree;

            memberService.checkEmailInUse(memberRequest)
            .then((isInUse) =>  {
                if (isInUse) {
                    formService.hideAllErrors(this.form);
                    formService.hideLoadingButton(this.form);
                    formService.showInputError(this.form, "email", "This email address is already taken");
                }
                else {
                    memberService.register(memberRequest)
                    .then(json => {
                        if (json.succeeded) {
                            memberService.goToMembers(json.result.successRedirect);
                        }
                        else {
                            formService.hideAllErrors(this.form);
                            formService.hideLoadingButton(this.form);
                            formService.showFormError(this.form, json.errors[0]);
                        }
                    });
                }
            })
        })
    }

    // Component
    render() {
        const { headers, body, actionLabel } = this.props.data;
        
        return (
            <section className="a4-create-account" ref={this.props.innerRef}>
                <div className="a4-create-account-container">
                    <div className="a4-create-account-content">
                        <div className="a4-create-account-info">
                            <h2 className="a4-create-account-title" data-aos={isMobileOnly ? undefined : "fade-up"}>{headers[0]} <span className="text-primary">{headers[1]}</span> {headers[2]}</h2>
                            <p className="a4-create-account-description" data-aos={isMobileOnly ? undefined : "fade-up"}>{body}</p>
                        </div>
                        <form className="a4-create-account-form" data-aos={isMobileOnly ? undefined : "fade-up"} ref={form => this.form = form} onSubmit={this.submitForm}>
                            <div className="a4-create-account-group">
                                <FullNameInput ignoreFullName={this.ignoreFullName} /> 
                            </div>
                            <div className="a4-create-account-group">
                                <div className="a4-input">
                                    <input name="email" type='email' required placeholder="E-mail address" />
                                    <label htmlFor="email">E-mail address</label>
                                    <span className="a4-input-error">Enter a valid e-mail address</span>
                                    <svg className="a4-svgsprite -error text-secondary">
                                        <Error />
                                    </svg>
                                </div>
                            </div>
                            <div className="a4-create-account-group">
                                <div className="a4-input">
                                    <input name="password" placeholder="Password" required type="password" />
                                    <label htmlFor="password">Password</label>
                                    <span className="a4-input-error">Enter a valid password</span>
                                    <svg className="a4-svgsprite -error text-secondary">
                                        <Error />
                                    </svg>
                                </div>
                            </div>
                            <div className="a4-create-account-group">
                                <div className="a4-input">
                                    <input name="confirm" placeholder="Confirm Password" required type="password" />
                                    <label htmlFor="password">Confirm Password</label>
                                    <span className="a4-input-error">Enter a valid password</span>
                                    <svg className="a4-svgsprite -error text-secondary">
                                        <Error />
                                    </svg>
                                </div>
                            </div>
                            <p className="a4-form-error"></p>
                            <div className="a4-create-account-control">
                                <BubbleButton className="a4-form-submit -primary" type="submit" formNoValidate>
                                    <b>{actionLabel}</b>
                                </BubbleButton>
                                <Link to="/login" className="a4-already-have-acc">Already have an account?</Link>
                            </div>
                            <div className="a4-create-account-terms">
                                By registering you agree to our &nbsp;
                                <Link to="/terms-and-conditions" className="md_inline"><b>Terms of Use</b></Link>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        )
    }
}

export default React.forwardRef((props, ref) => <TryForFree 
  innerRef={ref} {...props}
/>);