import React from 'react'
import { withRouter } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'

import BubbleButton from '../../shared/BubbleButton/BubbleButton'

import paymentService from '../../../services/PaymentService'

class Membership extends React.Component {
    constructor(props) {
        super(props);

        // This Binding
        this.goToBuy = this.goToBuy.bind(this);
    }

    // Event Handlers
    goToBuy(e) {
        paymentService.reset();
        paymentService.item = { ...this.props.data.item, quantity: 1 };
        this.props.history.push("/buy");
    }

    // Component
    render() {
        const title = this.props.data.item.title; 
        const description = this.props.data.item.descriptionText;
        const features = this.props.data.features;
        const price = this.props.data.item.price;

        const shortDescription = description.length > 300 ? description.substring(0, 350).split(" ").slice(0, -1).join(" ") + "..." : description;

        return (
            <li className="a4-plan-container" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-duration='1400' data-aos-delay="0" data-aos-anchor-placement="top-bottom">
                <div className="a4-plan-item">
                    <div className="a4-plan-section">
                        <h3 className="a4-plan-title">{title}</h3>
                        <p className="a4-plan-description">{shortDescription}</p>
                            <div className="a4-plan-section-subsection">
                                <BubbleButton onClick={this.goToBuy} className="a4-plan-buy-now -primary">Buy Now</BubbleButton>
                                <ul className="a4-plan-advantages -membership">
                                    {
                                        features.map((feature, featureIndex) => {
                                            return <li className="a4-plan-advantages-item -membership" key={featureIndex}>
                                                <div className="a4-plan-advantages-item-icon">
                                                    <img src={feature.imageUrl} alt="" />
                                                </div>
                                                <span className="a4-plan-advantages-item-text">{feature.value}</span>
                                            </li>
                                        })
                                    }
                                </ul>
                            </div>
                    </div>
                    <div className="a4-plan-section">
                        <div className="a4-plan-price">
                            <b className="a4-plan-price-digits">
                                <span className="a4-plan-price-int">${String(price).split('.')[0]}</span>
                                <span className="a4-plan-price-float">.{String(price).split('.')[1] ? String(price).split('.')[1] : '00'}</span>
                            </b>
                        </div>
                        <div className="a4-plan-controls -membership">
                            <BubbleButton onClick={this.goToBuy} className="a4-plan-buy-now -primary">Buy Now</BubbleButton>
                        </div>
                    </div>
                </div>
            </li>
        )
    }
}

export default withRouter(Membership);;