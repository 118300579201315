import httpService from './HttpService';

class FaqService {
    
    askQuestion(faqRequest) {
        const url = `${httpService.apiUrl}/api/faqs/ask`;
        const headers = {
            "Content-Type": "application/json",
            "HssEdSiteName": httpService.siteName,
            "Authorization": `Bearer ${httpService.apiToken}`,
            "HssEdSessionId": httpService.sessionId,
            "HssEdClientId": httpService.hssEdClientId,
            "HssEdClientSecret": httpService.hssEdClientSecret,
            "HssEdClientPlatform": httpService.hssEdClientPlatform
        };

        return httpService.post(url, headers, faqRequest);
    }
}

const faqService = new FaqService();
export default faqService;