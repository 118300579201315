import React from 'react'
import './AppStore.scss'
import { isMobileOnly } from 'react-device-detect'

import BubbleButton from '../../shared/BubbleButton/BubbleButton'
import { ReactComponent as Apple } from '../../../assets/apple.svg'
import { ReactComponent as Google } from '../../../assets/google.svg'

class AppStore extends React.Component {
    // Component
    render() {
        const { headers, body, appleUrl, googleUrl, imageUrl } = this.props.data;

        return (
            <section className="a4-appstore">
                <div className="a4-appstore-container">
                    <div className="a4-appstore-content">
                        <div className="a4-appstore-info">
                            <div className="a4-appstore-title">
                                <h2 data-aos={isMobileOnly ? undefined : "fade-up"}><span className="text-primary">{headers[0]} </span>{headers[1]}</h2>
                            </div>
                            <div className="a4-appstore-description" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-duration="1300">
                                <p>{body}</p>
                            </div>
                            <div className="a4-appstore-control">
                                {
                                    appleUrl &&
                                    <div className="a4-appstore-control-item" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-duration="1400">
                                        <a href={appleUrl}>
                                            <BubbleButton className="a4-btn_store">
                                                <div className="a4-btn_store-icon">
                                                    <svg className="a4-svgsprite -app-store">
                                                        <Apple />
                                                    </svg>
                                                </div>
                                                <div className="a4-btn_store-text">Available in <b>App Store</b></div>
                                            </BubbleButton>
                                        </a>
                                    </div>
                                }
                                {
                                    googleUrl &&
                                    <div className="a4-appstore-control-item" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-duration="1600">
                                        <a href={googleUrl}>
                                            <BubbleButton className="a4-btn_store">
                                                <div className="a4-btn_store-icon">
                                                    <svg className="a4-svgsprite -gplay-store">
                                                        <Google />
                                                    </svg>
                                                </div>
                                                <div className="a4-btn_store-text">Get it on <b>Google Play</b></div>
                                            </BubbleButton>
                                        </a>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="a4-appstore-gadget" data-aos={isMobileOnly ? undefined : "fade-up"}>
                            <img src={imageUrl} alt="" />
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default AppStore;