import React from 'react'
import './RadioButton.scss'

class RadioButton extends React.Component {
    // Component
    render() {
        return (
            <label className="a4-radio"> {this.props.text}
                <input type="radio" name={`radio${this.props.course}`} value={this.props.value} defaultChecked={this.props.index === 0} />
                <span className="a4-radio-button"></span>
            </label>
        )
    }
}

export default RadioButton;